@import "./mixins";
@import "./variables";

h1 {
  margin-bottom: 0;
  font-size: $font-size-2xl;
  font-weight: $font-weight-regular;
}

h2 {
  margin-bottom: 0;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
}

h3 {
  margin-bottom: 0;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
}

h4 {
  margin-bottom: 0;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}
