@import "./mixins";
@import "./variables";

.sp-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  border: 4px solid;
  border-width: 7px 26px 10px;
  border-radius: 100px;
  border-image-slice: 7 28 10 28 fill;
  border-image-repeat: repeat;
}

.sp-btn,
.sp-btn__arrow:active {
  box-shadow: none;
}

.sp-btn:disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  filter: drop-shadow(0 4px 0 rgba(255, 255, 255, 0));
  border-right-width: 26px;
  border-left-width: 26px;
  border-image-source: url("../assets/buttons/button_disabled-img.png");
  border-image-slice: 10 28 fill;
}

.sp-btn:disabled .icon {
  color: $color-content-neutral--disabled;
}

.sp-btn:focus,
.sp-btn__arrow:focus {
  outline: 0;
}

.sp-btn--primary {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(132, 33, 0, 0.5));
  filter: drop-shadow(0 4px 0 rgba(132, 33, 0, 0.5));
  border-image-source: url("../assets/buttons/button_primary-img.png");
}

.sp-btn--primary .icon {
  color: $color-content-neutral--white;
}

.sp-btn--secondary {
  color: $color-user-primary--main;
  -webkit-filter: drop-shadow(0 4px 0 rgba(170, 142, 132, 0.3));
  filter: drop-shadow(0 4px 0 rgba(170, 142, 132, 0.3));
  border-image-source: url("../assets/buttons/button_secondary-img.png");
}

.sp-btn--secondary .icon {
  color: $color-user-primary--main;
}

.sp-btn--danger {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(170, 142, 132, 0.3));
  filter: drop-shadow(0 4px 0 rgba(132, 33, 0, 0.5));
  border-image-source: url("../assets/buttons/button_danger-img.png");
}

.sp-btn--danger .icon {
  color: $color-content-neutral--white;
}

.sp-btn--shop {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(170, 142, 132, 0.3));
  filter: drop-shadow(0 4px 0 rgba(0, 93, 151, 0.5));
  border-image-source: url("../assets/buttons/button_shop-img.png");
}

.sp-btn--blue {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(170, 142, 132, 0.3));
  filter: drop-shadow(0 4px 0 rgba(0, 93, 151, 0.5));
  border-image-source: url("../assets/buttons/button_shop-img.png");
}

.sp-btn--iconOnly {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  border: none;
}

.sp-btn--iconOnly .icon {
  color: $color-user-primary--main;
}

.sp-btn--shop .icon {
  color: $color-content-neutral--white;
}

.sp-btn--white {
  color: $color-user-primary--main;
  background-blend-mode: color-burn;
  border-width: 10px 27px;
  border-image-source: url("../assets/buttons/button_white-img.png");
  border-image-slice: 10 16 fill;
  box-shadow: 0 4px 0 0 rgba(170, 142, 132, 0.3);
}

.sp-btn--white .icon {
  color: $color-user-primary--main;
}

.sp-btn--medium {
  width: 240px;
  height: 57px;
  padding: 0;
}

.sp-btn--small {
  width: 200px;
  height: 40px;
  border-width: 3px 20px 5px;
  border-image-slice: 5 22 fill;
}

.sp-btn--small.sp-btn:disabled {
  border-right-width: 20px;
  border-left-width: 20px;
  border-image-source: url("../assets/buttons/button_disabled_small-img.png");
  border-image-slice: 5 21 fill;
}

.sp-btn--small.sp-btn--danger {
  border-image-source: url("../assets/buttons/button_danger_small-img.png");
}

.sp-btn--small.sp-btn--primary {
  border-image-source: url("../assets/buttons/button_primary_small-img.png");
}

.sp-btn--small.sp-btn--secondary {
  border-image-source: url("../assets/buttons/button_secondary_small-img.png");
}

.sp-btn__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-btn--small.sp-btn--arrow-right .sp-btn__inner {
  margin-right: 16px;
}

.sp-btn--small.sp-btn--arrow-left .sp-btn__inner {
  margin-left: 16px;
}

.sp-btn--small .sp-btn__content {
  font-size: $font-size-xl;
}

.sp-btn--medium .sp-btn__content {
  font-size: $font-size-1xl;
}

.sp-btn__content {
  font-weight: $font-weight-bold;
  line-height: 150%;
  text-decoration: underline;
  white-space: nowrap;
}

.sp-btn--medium .sp-btn__content--small {
  font-size: $font-size-large;
}

.sp-btn--medium .sp-btn__content--medium {
  font-size: $font-size-xl;
}

.sp-btn__arrow {
  position: absolute;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $color-content-neutral--white;
  border-color: transparent;
  border-radius: 100px;
}

.sp-btn--white .sp-btn__arrow {
  background-color: $color-background-primary--lightest;
}

.sp-btn--small .sp-btn__arrow {
  width: 20px;
  height: 20px;
}

.sp-btn--medium .sp-btn__arrow {
  width: 24px;
  height: 24px;
}

.sp-btn--small .sp-btn__arrow--left {
  left: -10px;
}

.sp-btn--small .sp-btn__arrow--right {
  right: -10px;
}

.sp-btn--medium .sp-btn__arrow--left {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.sp-btn--medium .sp-btn__arrow--right {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.sp-btn__arrow .icon {
  color: $color-user-primary--main;
}

.sp-btn__arrow-blue .icon {
  color: $color-user-secondary--main;
}

.sp-btn__arrow:disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  background-color: $color-content-neutral--white;
}

.sp-btn-account--line {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.3));
  background-blend-mode: multiply;
  border-image-source: url("../assets/buttons/button_line-img.png");
}

.sp-btn-account--google {
  color: $color-content-neutral--white;
  -webkit-filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.3));
  background-blend-mode: multiply;
  border-image-source: url("../assets/buttons/button_google-img.png");
}

.sp-btn-account__icon {
  position: absolute;
  top: 0;
  left: -18px;
}

.sp-btn-account__space-left {
  width: 48px;
}

.sp-btn-account-margin {
  margin-left: 48px;
}

@media screen and (max-width: $mobile-view) {
  .sp-btn--mobile-fullwidth {
    width: 100% !important;
  }
}
