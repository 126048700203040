@import "./mixins";
@import "./variables";

.text {
  display: inline-block;
  font-weight: $font-weight-regular;
  color: $color-content-neutral--black;
  word-break: break-word;
  pointer-events: none;
}

.text--inline {
  display: inline;
}

.text--semibold {
  font-weight: $font-weight-semibold;
}

.text--bold {
  font-weight: $font-weight-bold;
}

.text--5xl {
  font-size: $font-size-5xl;
}

.text--4xl {
  font-size: $font-size-4xl;
}

.text--3xl {
  font-size: $font-size-3xl;
}

.text--2xl {
  font-size: $font-size-2xl;
}

.text--1xl {
  font-size: $font-size-1xl;
}

.text--xl {
  font-size: $font-size-xl;
}

.text--large {
  font-size: $font-size-large;
}

.text--base {
  font-size: $font-size-base;
}

.text--small {
  font-size: $font-size-small;
}

.text--xs {
  font-size: $font-size-xs;
}

.text--2xs {
  font-size: $font-size-2xs;
}

.text--placeholder {
  color: $color-content-neutral--disabled;
}

.text--white {
  color: $color-content-neutral--white;
}

.text--primary {
  color: $color-content-primary--main;
}

.text--success {
  color: $color-content-success--main;
}

.text--warning {
  color: $color-content-warning--main;
}

.text--warningDark {
  color: $color-content-warning--dark;
}

.text--orange {
  color: #cf4900;
}

.text--red {
  color: #ce1e1e;
}

.text--nowrap {
  white-space: nowrap;
}

.text--3xs {
  font-size: $font-size-3xs;
}

.text--neutralBlack {
  color: $color-content-neutral--black;
}

.text--neutralUserLight {
  color: $color-user-neutral-light;
}

.text--neutralUserLightest {
  color: $color-user-neutral--lightest;
}

.text--neutralLight {
  color: $color-content-neutral--light;
}

.text--neutralUserBlack {
  color: $color-user-neutral-black;
}

.text--secondary {
  color: $color-content-secondary--main;
}

.text--secondaryMain {
  color: $color-user-secondary--main;
}

.text--error {
  color: $color-content-error--main;
}

.text--information {
  color: $color-content-information--main;
}

.text--disabled {
  color: $color-content-neutral--disabled;
}

.text--normal {
  color: $color-text-normal;
}

.text--blue {
  color: $color-content-primary--accent;
}

.text--darkBlue {
  color: $color-user-secondary--main;
}

// change emp
.text--A21B11 {
  color: #a21b11;
}

// .text--ellipsis {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.text--ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text--background-color {
  padding: 5.5px 8px;
}

.text--title-font {
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.text--underline {
  text-decoration: underline;
}

.text--underline-yellow {
  line-height: 90%;
  border-bottom: solid 8px #f2c243;
}
