@import "./mixins";
@import "./variables";

.checkbox-group {
  display: flex;
}

.checkbox-group--row {
  flex-direction: row;
}

.checkbox-group--column {
  flex-direction: column;
}

.checkbox.checkbox--checked .checkbox-container {
  background-color: $color-background-primary--lightest;
  border: 2px solid $color-border-primary--main;
  border-radius: 4px;
  box-shadow: none;
}

.checkbox-group.checkbox-group--hasChecked
  .checkbox:not(.checkbox--checked, .checkbox--disabled)
  .checkbox-container {
  background-color: $color-background-neutral--white;
}

.checkbox {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}

.checkbox-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 11px 8px 8px;
  background-color: $color-user-secondary-not-entered;
  border: 1px solid rgba(24, 42, 61, 0.26);
  border-radius: 4px;
  box-shadow: 0 3px 0 0 rgba(24, 42, 61, 0.2);
}

.checkbox .checkbox__label {
  font-size: 18px;
  word-break: break-word;
}

.checkbox--disabled {
  pointer-events: none;
}

.checkbox--disabled .checkbox-container {
  background-color: $color-background-neutral-sp--disable;
}

.checkbox__input {
  position: relative;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  appearance: none;
  cursor: pointer;
  background-color: #ffffff;
  border-color: $color-content-neutral--lightest;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;
}

.checkbox__input:hover {
  border-color: $color-border-primary--main;
}

.checkbox__input:checked {
  background-color: $color-background-primary--main;
  border-color: $color-border-primary--main;
}

.checkbox__input:checked::after {
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  width: 10px;
  height: 5.5px;
  content: "";
  border-bottom: 2px solid #ffffff;
  border-left: 2px solid #ffffff;
  transform: rotate(-45deg);
}

.checkbox__input:indeterminate {
  background-color: $color-background-primary--main;
  border-color: $color-border-primary--main;
}

.checkbox__input:indeterminate::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 4.5px);
  display: block;
  width: 9px;
  content: "";
  border-bottom: 2px solid #ffffff;
}

.checkbox__input:disabled {
  background-color: $color-background-neutral-sp--disable;
  border-color: $color-border-divider;
}

.checkbox__input:disabled:checked {
  background-color: $color-content-neutral--lightest;
  border-color: $color-content-neutral--lightest;
}

.checkbox__input:disabled:indeterminate {
  background-color: $color-content-neutral--lightest;
  border-color: $color-content-neutral--lightest;
}

.checkbox--disabled .checkbox__label {
  color: $color-content-neutral--disabled;
}
