@import "./mixins";
@import "./variables";

.switch {
  display: inline-flex;
  gap: 8px;
  padding: 6px 0;
  margin: 0;
  cursor: pointer;
}

.switch--disabled {
  pointer-events: none;
}

.switch__input-wrapper {
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 100px;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.switch__input-label {
  position: relative;
  box-sizing: border-box;
  width: 32px;
  height: 20px;
  background: $color-background-neutral--light;
  border-radius: 46px;
  transition: 0.4s;
}

.switch__input-label::after {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 2;
  width: 16px;
  height: 16px;
  content: "";
  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.switch--disabled .switch__input-label {
  background-color: $color-background-neutral--lightest;
}

.switch__input:checked + .switch__input-label {
  background-color: $color-background-primary--main;
}

.switch--disabled .switch__input:checked + .switch__input-label {
  background-color: $color-content-neutral--disabled;
}

.switch__input:checked + .switch__input-label::after {
  left: 14px;
}

.switch__label {
  margin: 0;
  line-height: 145%;
  color: $color-content-neutral--black;
}

.switch--disabled .switch__label {
  color: $color-content-neutral--disabled;
}

@media only screen and (max-width: $mobile-view) {
  .switch__input-label {
    background: $color-background-neutral-sp--light;
  }
}
