@import "./mixins";
@import "./variables";

.calendar-day {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  width: 100%;
}

.calendar-day__holder {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 100%;
}

.calendar-day__month {
  color: $color-user-neutral-black;
}

.calendar-day__container {
  width: 100%;
  padding: 8px;
  margin-bottom: 0;
  border: 1px solid rgba(24, 42, 61, 0.26);
  border-radius: 4px;
  box-shadow: 0 3px 0 0 rgba(24, 42, 61, 0.2);
}

.calendar-day__item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendar-day__item--saturday .text {
  color: $color-user-secondary--main !important;
}

.calendar-day__item--sunday .text {
  color: $color-user-primary--main !important;
}

.calendar-day__item input[type="radio"] {
  width: 0;
  height: 0;
  visibility: hidden;
}

.calendar-day--selected {
  background: $color-background-primary--lightest;
  border: 1px solid $color-user-primary--main;
  box-shadow: none;
}

.calendar-day__container:hover {
  border: 1px solid $color-user-primary--main;
}
