@import "./mixins";
@import "./variables";

.spinner {
  display: inline-flex;
  animation: rotation 1000ms infinite linear;
}

// addEmp: 全体のロードスピナーの背景をマスク
.spinner--loading {
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

// addEmp: 全体のロードスピナーを中央に配置
.spinner--container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #cf4900;
  border-radius: 4px;
}

// addEmp: 全体のロードスピナーの動作文言
.spinner--action {
  font-size: 20px;
  color: $color-border-neutral--white;
}

.spinner--main svg path {
  stroke: $color-border-primary--main;
}

.spinner--white svg path {
  stroke: $color-border-neutral--white;
}

.spinner--neutral svg path {
  stroke: $color-border-neutral--black;
}

.spinner--danger svg path {
  stroke: $color-border-error--main;
}

.spinner--gray svg path {
  stroke: $color-user-neutral--lightest;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
