@import "./mixins";
@import "./variables";

.progress-bar {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  width: 200px;
}

.progress-bar__progress {
  width: 100%;
  height: 4px;
  border-radius: 100px;
}

.progress-bar__progress::-webkit-progress-bar {
  background: $color-background-neutral--extra-bk-6;
  border-radius: 100px;
}

.progress-bar__progress::-webkit-progress-value {
  background: $color-content-primary--accent;
  border-radius: 100px;
}

.progress-bar__progress::-moz-progress-bar {
  background: $color-background-neutral--extra-bk-6;
  border-radius: 100px;
}

.progress-bar__progress::-ms-fill {
  background: $color-content-primary--accent;
  border-radius: 100px;
}

.progress-bar__percentage {
  font-size: $font-size-xs;
}

.progress-bar__biggerBar {
  width: 100%;
  height: 32px;
  border-radius: 8px;
}

.progress-bar__biggerBar::-webkit-progress-bar {
  background: $color-background-neutral--extra-bk-6;
  border-radius: 8px;
}

.progress-bar__biggerBar-primary::-webkit-progress-value {
  background: $color-content-primary--accent;
  border-radius: 8px 0 0 8px;
}

.progress-bar__biggerBar-warning::-webkit-progress-value {
  background: $color-content-primary--accent;
  background-color: $color-background-warning--main;
  border-radius: 8px 0 0 8px;
}

.progress-bar__biggerBar-error::-webkit-progress-value {
  background: $color-content-primary--accent;
  background-color: $color-background-error--dark;
  border-radius: 8px 0 0 8px;
}

.progress-bar__biggerBar::-moz-progress-bar {
  background: $color-background-neutral--extra-bk-6;
  border-radius: 8px;
}

.progress-bar__biggerBar::-ms-fill {
  background: $color-content-primary--accent;
  border-radius: 8px;
}
