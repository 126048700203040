@import "./mixins";
@import "./variables";

.card-cart {
  width: 100%;
}

.card-cart__container {
  display: flex;
  width: 100%;
  padding: 16px 0;
}

.card-cart__detail {
  display: flex;
  gap: 8px;
  width: 100%;
}

.card-cart__button-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

// mobile

@media screen and (max-width: $mobile-view) {
  .card-cart .card-cart__detail {
    flex-direction: column;
    padding: 0;
  }

  .card-cart .card-cart__image .image {
    width: 88px;
    height: 88px;
  }

  .card-cart .card-cart__button-form {
    flex-direction: row;
    gap: 24px;
    align-items: flex-end;
  }

  .card-cart__button,
  .card-cart__button .sp-btn {
    width: 100% !important;
  }
}
