@import "./mixins";
@import "./variables";

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: $color-background-neutral--white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.card-top {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.card-footer {
  padding: 12px 16px;
  border-radius: 0 0 8px 8px;
}

.card-footer--blue {
  background-color: $color-user-secondary-lightest;
}

.card-footer--blue .text {
  color: $color-user-secondary--main;
}

.card-footer--gray {
  background-color: $color-background-neutral-sp--lightest;
}

.card-footer--gray .text {
  color: $color-user-neutral-light;
}

.card-text-area {
  display: flex;
  gap: 8px;
  width: 100%;
}

.card-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  line-height: 150%;
}

.card--button {
  // flex-direction: column;
  gap: 8px;
}

.card-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.card-content-mh-50px {
  min-height: 50px;
}

.card-content-mh-80px {
  min-height: 80px;
}

.card-content-mh-85px {
  min-height: 85px;
}

.card-arrow {
  display: flex;
  align-items: center;
  height: 100%;
}

.card .card-arrow .icon {
  color: $color-user-primary--main;
}

.card--disabled {
  background: $color-background-neutral-sp--disable;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.card--disabled .text {
  color: $color-content-neutral-sp--disable;
}

.card--disabled .card-arrow .icon {
  color: $color-content-neutral-sp--disable;
}

.card__icon-text--success {
  color: $color-content-success--main;
}

.card__icon-text--warning {
  color: $color-content-warning--dark;
}

.card__icon-text--error {
  color: $color-content-error--main;
}

.card__icon-text--pending,
.card__icon-text--waiting,
.card__icon-text--no-examination,
.card__icon-text--done {
  width: 48px;
  color: $color-user-neutral-light;
}

.card--clickable {
  cursor: pointer;
}

// mobile

@media only screen and (max-width: $mobile-view) {
  .card--button {
    flex-direction: column;
    gap: 8px;
  }

  .card--button .card-top {
    flex-direction: column;
    gap: 16px;
  }
}
