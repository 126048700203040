@import "./mixins";
@import "./variables";

.avatar {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $color-content-primary--main;
  cursor: pointer;
  background: $color-background-primary--active;
  border-radius: 50%;
}

.avatar--border {
  border-color: $color-border-neutral--white;
  border-style: solid;
  border-width: 1px;
}

.avatar--small {
  width: 24px;
  height: 24px;
  font-size: $font-size-3xs;
}

.avatar--medium {
  width: 32px;
  height: 32px;
}

.avatar--large {
  width: 40px;
  height: 40px;
}

.avatar .icon {
  color: $color-content-primary--main;
}

.avatar .badge {
  position: absolute;
  top: calc(50% - 20px / 2 - 12px);
  left: calc(50% - 20px / 2 + 12px);
}

.avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
