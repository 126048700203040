@import "mixins";
@import "variables";

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  padding: 2px 8px;
  font-size: $font-size-2xs;
  color: $color-content-neutral--white;
  border-color: $color-border-neutral--white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
}

.badge--primary {
  background-color: $color-background-primary--main;
}

.badge--secondary {
  background-color: $color-user-secondary--main;
}

.badge--red {
  background-color: $color-background-error--main;
}

.badge--blue {
  background-color: $color-background-primary--accent;
}

// changeEmp
.badge--warning {
  background-color: $color-background-warning--main;
}

.badge--information {
  color: $color-content-primary--accent;
  background-color: $color-background-neutral--light;
  border-color: $color-content-primary--accent;
}

.badge--small {
  min-width: 16px;
  height: 16px;
  padding: 0 3px;
  font-size: $font-size-3xs;
  border-radius: 16px;
}

.badge--large {
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0 8px;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  border-radius: 16px;
}

.badge--disabled {
  background-color: $color-content-neutral--disabled;
}
