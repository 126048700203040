@import "mixins";
@import "variables";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 6px;
  white-space: nowrap;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.btn--large {
  @include \.font-component-button-large;

  height: 40px;
  padding: 8px;
}

.btn--medium {
  @include \.font-component-button-large;

  height: 56px;
  padding: 8px 16px;
}

.btn--small {
  @include \.font-component-sp-link-medium;

  height: 35px;
  padding: 4px 10px;
}

.btn:focus {
  outline: 0;
}

.btn__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.btn__content {
  display: flex;
  align-items: center;
}

.btn__content-underlined {
  text-decoration: underline;
}

.btn--circle.btn--small {
  border-radius: 100px;
}

.btn--circle.btn--medium {
  border-radius: 40px;
}

.btn--circle.btn--large {
  border-radius: 40px;
}

.btn--icon-only.btn--small {
  width: 24px;
}

.btn--icon-only.btn--medium {
  width: 32px;
}

.btn--icon-only.btn--large {
  width: 40px;
}

.btn--primary {
  color: $color-content-neutral--white;
}

.btn--primary .icon {
  color: $color-content-neutral--white;
}

.icon {
  display: flex !important;
}

.btn--primary.btn--main {
  background-color: #d66a2f;
  background-image: url("../assets/button-background.png");
  background-blend-mode: color-burn;
  box-shadow: 0 4px 0 0 rgba(132, 33, 0, 0.5);
}

.btn--primary.btn--main:hover {
  background-color: #d66a2f;
}

.btn--primary.btn--main:active {
  background-color: #d66a2f;
}

.btn--primary.btn--main:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--disabled;
}

.btn--primary.btn--main:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--primary.btn--danger {
  background-color: $color-background-error--dark;
  box-shadow: 0 4px 0 0 #84210080;
}

.btn--primary.btn--danger:hover {
  background-color: $color-background-error--hover;
}

.btn--primary.btn--danger:active {
  background-color: $color-background-error--selected;
}

.btn--primary.btn--danger:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--disabled;
}

.btn--primary.btn--danger:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--primary.btn--background-dark {
  color: $color-content-primary--main;
  background-color: $color-background-neutral--white;
  box-shadow: $shadow-level-01;
}

.btn--primary.btn--background-dark .icon {
  color: $color-content-primary--main;
}

.btn--primary.btn--background-dark:hover {
  background-color: $color-background-neutral--extra-wh-50;
}

.btn--primary.btn--background-dark:active {
  background-color: $color-background-neutral--extra-wh-80;
}

.btn--primary.btn--background-dark:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--disabled;
}

.btn--primary.btn--background-dark:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--user.btn--background-dark {
  color: $color-content-primary--main;
  background-color: $color-background-neutral--white;
  box-shadow: $shadow-level-01;
}

.btn--user.btn--background-dark .icon {
  color: $color-content-primary--main;
}

.btn--secondary {
  background-color: $color-background-secondary--main;
}

.btn--secondary.btn--main {
  color: $color-content-primary--main;
  border-color: $color-background-secondary--main;
  box-shadow: 0 4px 0 0 rgba(170, 142, 132, 0.3);
}

.btn--secondary.btn--main .icon {
  color: $color-content-primary--main;
}

.btn--secondary.btn--main:hover {
  color: $color-content-primary--main;
  border-color: $color-border-primary--main;
}

.btn--secondary.btn--main:hover .icon {
  color: $color-content-primary--main;
}

.btn--secondary.btn--main:active {
  color: $color-content-primary--dark;
  border-color: $color-content-primary--dark;
}

.btn--secondary.btn--main:active .icon {
  color: $color-content-primary--dark;
}

.btn--secondary.btn--main:disabled {
  color: $color-content-neutral--disabled;
  border-color: $color-border-neutral--disabled;
}

.btn--secondary.btn--main:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--secondary.btn--danger {
  color: $color-content-error--main;
  border-color: $color-border-error--main;
}

.btn--secondary.btn--danger .icon {
  color: $color-content-error--main;
}

.btn--secondary.btn--danger:hover {
  color: $color-content-error--light;
  border-color: $color-border-error--light;
}

.btn--secondary.btn--danger:hover .icon {
  color: $color-content-error--light;
}

.btn--secondary.btn--danger:active {
  color: $color-content-error--dark;
  border-color: $color-border-error--dark;
}

.btn--secondary.btn--danger:active .icon {
  color: $color-content-error--dark;
}

.btn--secondary.btn--danger:disabled {
  color: $color-content-neutral--disabled;
  border-color: $color-border-neutral--disabled;
}

.btn--secondary.btn--danger:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--secondary.btn--background-dark {
  color: $color-border-neutral--white;
  border-color: $color-border-neutral--white;
}

.btn--secondary.btn--background-dark .icon {
  color: $color-border-neutral--white;
}

.btn--secondary.btn--background-dark:hover {
  color: $color-background-neutral--extra-wh-50;
  border-color: $color-background-neutral--extra-wh-50;
}

.btn--secondary.btn--background-dark:hover .icon {
  color: $color-background-neutral--extra-wh-50;
}

.btn--secondary.btn--background-dark:active {
  color: $color-background-neutral--extra-wh-80;
  border-color: $color-background-neutral--extra-wh-80;
}

.btn--secondary.btn--background-dark:active .icon {
  color: $color-background-neutral--extra-wh-80;
}

.btn--secondary.btn--background-dark:disabled {
  color: $color-background-neutral--extra-wh-25;
  border-color: $color-background-neutral--extra-wh-25;
}

.btn--secondary.btn--background-dark:disabled .icon {
  color: $color-background-neutral--extra-wh-25;
}

.btn--sub.btn--main {
  color: $color-content-primary--main;
  background-color: $color-background-neutral--white;
  border: 1px solid $color-border-primary--main;
}

.btn--sub.btn--main .icon {
  color: $color-content-primary--main;
}

.btn--sub.btn--main:hover {
  color: $color-content-primary--main;
  background-color: $color-background-primary--lightest;
}

.btn--sub.btn--main:hover .icon {
  color: $color-content-primary--light;
}

.btn--sub.btn--main:active {
  color: $color-content-primary--dark;
  background-color: $color-background-primary--active;
}

.btn--sub.btn--main:active .icon {
  color: $color-content-primary--dark;
}

.btn--sub.btn--main:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--extra-bk-4;
}

.btn--sub.btn--main:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--sub.btn--danger {
  color: $color-content-error--main;
  background-color: $color-background-error--lightest;
}

.btn--sub.btn--danger .icon {
  color: $color-content-error--main;
}

.btn--sub.btn--danger:hover {
  color: $color-content-error--light;
  background-color: $color-background-error--hover-faded;
}

.btn--sub.btn--danger:hover .icon {
  color: $color-content-error--light;
}

.btn--sub.btn--danger:active {
  color: $color-content-error--dark;
  background-color: $color-background-error--active;
}

.btn--sub.btn--danger:active .icon {
  color: $color-content-error--dark;
}

.btn--sub.btn--danger:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--extra-bk-4;
}

.btn--sub.btn--danger:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--sub.btn--neutral {
  color: $color-border-neutral--black;
  background-color: $color-background-neutral--extra-bk-6;
}

.btn--sub.btn--neutral .icon {
  color: $color-border-neutral--black;
}

.btn--sub.btn--neutral:hover {
  color: $color-border-neutral--light;
  background-color: $color-background-neutral--extra-bk-10;
}

.btn--sub.btn--neutral:hover .icon {
  color: $color-border-neutral--light;
}

.btn--sub.btn--neutral:active {
  color: $color-border-neutral--black;
  background-color: $color-background-neutral--extra-bk-16;
}

.btn--sub.btn--neutral:active .icon {
  color: $color-border-neutral--black;
}

.btn--sub.btn--neutral:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--extra-bk-4;
}

.btn--sub.btn--neutral:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn--sub.btn--background-dark {
  color: $color-content-neutral--white;
  background-color: $color-background-neutral--extra-wh6;
}

.btn--sub.btn--background-dark .icon {
  color: $color-content-neutral--white;
}

.btn--sub.btn--background-dark:hover {
  color: $color-content-neutral--white;
  background-color: $color-background-neutral--extra-wh-25;
}

.btn--sub.btn--background-dark:hover .icon {
  color: $color-content-neutral--white;
}

.btn--sub.btn--background-dark:active {
  color: $color-content-neutral--white;
  background-color: $color-background-neutral--extra-bk-16;
}

.btn--sub.btn--background-dark:active .icon {
  color: $color-content-neutral--white;
}

.btn--sub.btn--background-dark:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--extra-wh3;
}

.btn--sub.btn--background-dark:disabled .icon {
  color: $color-content-neutral--disabled;
}

.btn:hover {
  box-shadow: none;
}

.btn:active {
  box-shadow: none;
}

.btn:disabled {
  pointer-events: none;
  box-shadow: none;
}

.btn__spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn--loading {
  pointer-events: none;
}

.btn--large .btn__content {
  margin: 0 8px;
}

.btn--medium .btn__content {
  margin: 0 6px;
}

.btn--small .btn__content {
  margin: 0 4px;
}

.btn.btn--borderless {
  border: none;
}

.btn.btn--border-round {
  border-radius: 100px;
}

@media screen and (max-width: $mobile-view) {
  .view-products--button .icon {
    display: none !important;
  }

  .btn--adjusted {
    width: 100% !important;
  }
}
