@import "mixins";
@import "variables";

.icon {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
  color: $color-content-neutral--light;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
