@import "./mixins";
@import "./variables";

.dialog-custom-wrapper {
  position: fixed;
  inset: 0;
  z-index: 999;
  display: none;
  align-items: center;
  justify-content: center;
  background: $color-background-neutral--extra-bk-50;
}

.dialog-custom {
  max-width: calc((100% - 40px) - 2em);
  max-height: calc((100% - 40px) - 2em);
  padding: 0;
  overflow: visible;
  background: $color-background-neutral--white;
  border: none;
  border-radius: 8px;
  box-shadow: $shadow-level-04;
  animation: fadeIn 300ms ease 0s 1 normal;
}

.dialog-custom--small {
  width: 464px;
}

.dialog-custom--medium {
  width: 640px;
}

.dialog-custom--large {
  width: 960px;
}

.dialog-custom--fullscreen {
  width: calc(100% - 64px);
  margin: 32px;
}

.dialog-custom-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dialog-custom__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid $color-border-divider;
}

.dialog-custom__header-inner {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.dialog-custom__title {
  display: block;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  line-height: 30px;
  color: $color-content-neutral--black;
}

.dialog-custom__sub-title {
  font-weight: $font-weight-bold;
  line-height: 21px;
  color: $color-content-neutral--light;
}

.dialog-custom__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.dialog-custom__footer {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  box-shadow: $shadow-level-02top;
}

.dialog-custom__footer--centered {
  gap: 16px;
  justify-content: center;
}

.dialog-custom-wrapper.open-animation {
  animation: fadeIn 150ms ease 0s 1 normal;
}

.dialog-custom-wrapper.close-animation {
  animation: fadeOut 150ms ease 0s 1 normal forwards;
}

.dialog-custom-wrapper.open {
  display: flex;
}

.dialog-custom__close-button {
  top: 16px;
  right: 32px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// mobile

.dialog-custom-mobile {
  max-width: calc(100% - 32px);
  height: 100% !important;
  max-height: calc(100% - 32px);
}

.dialog-custom-mobile .dialog-custom__content {
  padding: 16px;
}

.dialog-custom-mobile .dialog-custom__footer--centered {
  flex-direction: column-reverse;
  padding: 16px;
}

.dialog-custom-mobile .dialog-custom__header {
  padding: 16px;
}

.dialog-custom-mobile .dialog__footer .sp-btn {
  width: 100% !important;
}
