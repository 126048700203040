@import "./mixins";
@import "./variables";

.dialog {
  position: relative;
  padding: 0;
  overflow: hidden;
  background: $color-background-neutral--white;
  border: none;
  border-radius: 8px;
  box-shadow: $shadow-level-04;
}

.dialog::backdrop {
  background: $color-background-neutral--extra-bk-50;
}

.dialog--xs {
  width: 343px;
}

.dialog--small {
  width: 464px;
}

.dialog--medium {
  width: 640px;
}

.dialog--large {
  width: 960px;
}

.dialog--xl {
  width: 1280px;
}

.dialog--fullscreen {
  width: calc(100% - 64px);
  margin: 32px;
}

.dialog-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dialog__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid $color-border-divider;
}

.dialog__header-inner {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.dialog__title {
  display: block;
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
  line-height: 30px;
  color: $color-content-neutral--black;
}

.dialog__sub-title {
  font-weight: $font-weight-bold;
  line-height: 21px;
  color: $color-content-neutral--light;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 16px 32px;
  overflow-y: auto;
}

.dialog__footer {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 32px;
  box-shadow: $shadow-level-02top;
}

.dialog[open] {
  animation: fadeIn 300ms ease 0s 1 normal;
}

.dialog[open]::backdrop {
  animation: fadeIn 150ms ease 0s 1 normal;
}

.dialog.close {
  animation: fadeOut 150ms ease 0s 1 normal forwards;
}

.dialog.close::backdrop {
  animation: fadeOut 150ms ease 0s 1 normal forwards;
}

.dialog__close-button {
  position: absolute;
  top: 16px;
  right: 32px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media screen and (max-width: $mobile-view) {
  .dialog__footer {
    justify-content: center !important;
  }
}
