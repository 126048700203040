.online-vaudeville {
  & > .sheet {
    padding: 32px 24px 24px !important;
  }

  &__image--sp {
    position: relative;
    display: none;

    .image--enjoy {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -83%);
    }
  }

  &__image--pc {
    position: relative;

    .image--enjoy {
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, -100%);
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 70px;
    width: 100%;
  }
}

@media screen and (max-width: $mobile-view) {
  .online-vaudeville {
    &__image--sp {
      display: block;
    }

    &__content {
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    &__image--pc {
      display: none;
    }
  }

  .online-vaudeville__list > .online-vaudeville__list__item:not(:first-child) {
    display: none;
  }
}
