@import "./mixins";
@import "./variables";

.divider {
  display: flex;
  width: 100%;
  height: 1px;
  padding: 0;
  margin: 0;
  border-top: 1px solid #ffffff33;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.divider--vertical {
  display: inline-block;
  width: 1px;
  height: auto;
  min-height: 16px;
  border-top: 0;
  border-left: 1px solid $color-border-neutral--white;
}

.divider--darkColor {
  border-color: $color-border-divider;
}

.divider--white {
  border-color: $color-border-neutral--white;
}

.divider--orange {
  border-color: #e79376;
  border-width: 3px;
}

.divider--ghost {
  border-color: #ffffff33;
}

.divider--primary-main {
  border-color: $color-user-primary--main;
}

.divider--vertical.divider--primary-main {
  border-left: 1px solid $color-user-primary--main;
}

.divider--vertical.divider--darkColor {
  border-left-color: $color-border-divider;
}
