@import "./mixins";
@import "./variables";

.menulist {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.menulist-item {
  display: flex;
  flex-shrink: 0;
  column-gap: 4px;
  align-items: center;
  min-height: 26px;
  padding: 8px 16px;
  overflow: hidden;
  font-size: $font-size-xs;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: normal;
}

.menulist-item:hover {
  cursor: pointer;
  background-color: $color-background-primary--hover-faded;
}

.menulist-item--selected {
  font-weight: $font-weight-bold;
  color: $color-content-primary--main;
  background-color: $color-background-primary--active;
}

.menulist-item--danger {
  color: $color-content-error--main;
}

.menulist-item--danger .icon {
  color: $color-content-error--main;
}

.menulist-item .checkbox {
  margin-right: 2px;
}

.menulist-item--danger:hover {
  background-color: $color-background-error--hover-faded;
}

.menulist-item__label {
  display: inline-flex;
  align-items: center;
}

.menulist-item .checkbox span {
  padding: 0;
  line-height: 150%;
}

.menulist-item .checkbox__input {
  margin-top: 11px;
}

.menulist-item--settings,
.menulist-item--change-pass,
.menulist-item--change-email,
.menulist-item--logout {
  padding: 2.5px 16px;
  font-size: 12px;
}

.menulist-item--info {
  padding: 8px 16px;
}

.menulist-item--info:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.menulist-item--settings,
.menulist-item--change-pass,
.menulist-item--change-email,
.menulist-item--logout,
.menulist-item--info {
  cursor: pointer;
}

.menulist-item--settings:hover,
.menulist-item--change-email:hover,
.menulist-item--change-pass:hover {
  background: $color-background-primary--hover-faded;
}

.menulist-item--logout:hover {
  background: $color-background-error--hover-faded;
}

.menulist-item--profile {
  padding-bottom: 8px;
}

.menulist-item--info-date {
  font-size: 13px;
}

.menulist-item--profile-id {
  font-size: 12px;
}

.icon--person,
.icon--description,
.icon--mail,
.icon--help,
.icon--lock,
.menulist-item--profile-id,
.menulist-item--info-date {
  color: $color-content-neutral--light !important;
}

.icon--logout {
  color: $color-content-error--dark !important;
}

.menulist-item--logout {
  color: $color-content-error--main !important;
}
