@mixin \.util-flex {
  display: flex;
}

.util-flex {
  @include \.util-flex;
}

@mixin \.util-flex--align-center {
  align-items: center;
}

.util-flex--align-center {
  @include \.util-flex--align-center;
}

@mixin \.util-flex--align-end {
  align-items: flex-end;
}

.util-flex--align-end {
  @include \.util-flex--align-end;
}

@mixin \.util-flex--align-start {
  align-items: flex-start;
}

.util-flex--align-start {
  @include \.util-flex--align-start;
}

@mixin \.util-flex--column {
  flex-direction: column;
}

.util-flex--column {
  @include \.util-flex--column;
}

@mixin \.util-flex--gap4 {
  gap: 4px;
}

.util-flex--gap4 {
  @include \.util-flex--gap4;
}

@mixin \.util-flex--gap8 {
  gap: 8px;
}

.util-flex--gap8 {
  @include \.util-flex--gap8;
}

@mixin \.util-flex--gap16 {
  gap: 16px;
}

.util-flex--gap16 {
  @include \.util-flex--gap16;
}

@mixin \.util-flex--gap40 {
  gap: 40px !important;
}

.util-flex--gap40 {
  @include \.util-flex--gap40;
}

@mixin \.util-flex--row-gap4 {
  row-gap: 4px;
}

.util-flex--row-gap4 {
  @include \.util-flex--row-gap4;
}

@mixin \.util-flex--row-gap8 {
  row-gap: 8px;
}

.util-flex--row-gap8 {
  @include \.util-flex--row-gap8;
}

@mixin \.util-flex--row-gap16 {
  row-gap: 16px;
}

.util-flex--row-gap16 {
  @include \.util-flex--row-gap16;
}

@mixin \.util-flex--row-gap40 {
  row-gap: 40px;
}

.util-flex--row-gap40 {
  @include \.util-flex--row-gap40;
}

@mixin \.util-flex--column-gap4 {
  column-gap: 4px;
}

.util-flex--column-gap4 {
  @include \.util-flex--column-gap4;
}

@mixin \.util-flex--column-gap8 {
  column-gap: 8px;
}

.util-flex--column-gap8 {
  @include \.util-flex--column-gap8;
}

@mixin \.util-flex--column-gap16 {
  column-gap: 16px;
}

.util-flex--column-gap16 {
  @include \.util-flex--column-gap16;
}

@mixin \.util-flex--column-gap40 {
  column-gap: 40px;
}

.util-flex--column-gap40 {
  @include \.util-flex--column-gap40;
}

@mixin \.util-flex--grow-one {
  flex-grow: 1;
}

.util-flex--grow-one {
  @include \.util-flex--grow-one;
}

@mixin \.util-flex--justify-between {
  justify-content: space-between;
}

.util-flex--justify-between {
  @include \.util-flex--justify-between;
}

@mixin \.util-flex--justify-center {
  justify-content: center;
}

.util-flex--justify-center {
  @include \.util-flex--justify-center;
}

@mixin \.util-flex--justify-end {
  justify-content: flex-end;
}

.util-flex--justify-end {
  @include \.util-flex--justify-end;
}

@mixin \.util-flex--justify-start {
  justify-content: flex-start;
}

.util-flex--justify-start {
  @include \.util-flex--justify-start;
}

@mixin \.util-flex--row {
  flex-direction: row;
}

.util-flex--row {
  @include \.util-flex--row;
}

@mixin \.util-flex--wrap {
  flex-wrap: wrap !important;
}

.util-flex--wrap {
  @include \.util-flex--wrap;
}

@mixin \.util-full-width {
  width: 100%;
}

.util-full-width {
  @include \.util-full-width;
}

@mixin \.util-full-height {
  height: 100%;
}

.util-full-height {
  @include \.util-full-height;
}

@mixin \.util-mb-0 {
  margin-bottom: 0 !important;
}

.util-mb-0 {
  @include \.util-mb-0;
}

@mixin \.util-mb-3 {
  margin-bottom: 3px !important;
}

.util-mb-3 {
  @include \.util-mb-3;
}

@mixin \.util-mb-4 {
  margin-bottom: 4px !important;
}

.util-mb-4 {
  @include \.util-mb-4;
}

@mixin \.util-mb-8 {
  margin-bottom: 8px !important;
}

.util-mb-8 {
  @include \.util-mb-8;
}

@mixin \.util-mb-12 {
  margin-bottom: 12px !important;
}

.util-mb-12 {
  @include \.util-mb-12;
}

@mixin \.util-mb-16 {
  margin-bottom: 16px !important;
}

.util-mb-16 {
  @include \.util-mb-16;
}

@mixin \.util-mb-20 {
  margin-bottom: 20px !important;
}

.util-mb-20 {
  @include \.util-mb-20;
}

@mixin \.util-mb-24 {
  margin-bottom: 24px !important;
}

.util-mb-24 {
  @include \.util-mb-24;
}

@mixin \.util-mb-32 {
  margin-bottom: 32px !important;
}

.util-mb-32 {
  @include \.util-mb-32;
}

@mixin \.util-mb-40 {
  margin-bottom: 40px !important;
}

.util-mb-40 {
  @include \.util-mb-40;
}

@mixin \.util-mb-48 {
  margin-bottom: 48px !important;
}

.util-mb-48 {
  @include \.util-mb-48;
}

@mixin \.util-mb-72 {
  margin-bottom: 72px !important;
}

.util-mb-72 {
  @include \.util-mb-72;
}

@mixin \.util-ml-4 {
  margin-left: 4px !important;
}

.util-ml-4 {
  @include \.util-ml-4;
}

@mixin \.util-ml-8 {
  margin-left: 8px !important;
}

.util-ml-8 {
  @include \.util-ml-8;
}

@mixin \.util-ml-12 {
  margin-left: 12px !important;
}

.util-ml-12 {
  @include \.util-ml-12;
}

@mixin \.util-ml-16 {
  margin-left: 16px !important;
}

.util-ml-16 {
  @include \.util-ml-16;
}

@mixin \.util-ml-20 {
  margin-left: 20px !important;
}

.util-ml-20 {
  @include \.util-ml-20;
}

@mixin \.util-ml-24 {
  margin-left: 24px !important;
}

.util-ml-24 {
  @include \.util-ml-24;
}

@mixin \.util-ml-32 {
  margin-left: 32px !important;
}

.util-ml-32 {
  @include \.util-ml-32;
}

@mixin \.util-ml-40 {
  margin-left: 40px !important;
}

.util-ml-40 {
  @include \.util-ml-40;
}

@mixin \.util-ml-48 {
  margin-left: 48px !important;
}

.util-ml-48 {
  @include \.util-ml-48;
}

@mixin \.util-mr-4 {
  margin-right: 4px !important;
}

.util-mr-4 {
  @include \.util-mr-4;
}

@mixin \.util-mr-8 {
  margin-right: 8px !important;
}

.util-mr-8 {
  @include \.util-mr-8;
}

@mixin \.util-mr-12 {
  margin-right: 12px !important;
}

.util-mr-12 {
  @include \.util-mr-12;
}

@mixin \.util-mr-16 {
  margin-right: 16px !important;
}

.util-mr-16 {
  @include \.util-mr-16;
}

@mixin \.util-mr-20 {
  margin-right: 20px !important;
}

.util-mr-20 {
  @include \.util-mr-20;
}

@mixin \.util-mr-24 {
  margin-right: 24px !important;
}

.util-mr-24 {
  @include \.util-mr-24;
}

@mixin \.util-mr-32 {
  margin-right: 32px !important;
}

.util-mr-32 {
  @include \.util-mr-32;
}

@mixin \.util-mr-40 {
  margin-right: 40px !important;
}

.util-mr-40 {
  @include \.util-mr-40;
}

@mixin \.util-mr-48 {
  margin-right: 48px !important;
}

.util-mr-48 {
  @include \.util-mr-48;
}

@mixin \.util-mt-4 {
  margin-top: 4px !important;
}

.util-mt-4 {
  @include \.util-mt-4;
}

@mixin \.util-mt-8 {
  margin-top: 8px !important;
}

.util-mt-8 {
  @include \.util-mt-8;
}

@mixin \.util-mt-12 {
  margin-top: 12px !important;
}

.util-mt-12 {
  @include \.util-mt-12;
}

@mixin \.util-mt-16 {
  margin-top: 16px !important;
}

.util-mt-16 {
  @include \.util-mt-16;
}

@mixin \.util-mt-20 {
  margin-top: 20px !important;
}

.util-mt-20 {
  @include \.util-mt-20;
}

@mixin \.util-mt-24 {
  margin-top: 24px !important;
}

.util-mt-24 {
  @include \.util-mt-24;
}

@mixin \.util-mt-32 {
  margin-top: 32px !important;
}

.util-mt-32 {
  @include \.util-mt-32;
}

@mixin \.util-mt-40 {
  margin-top: 40px !important;
}

.util-mt-40 {
  @include \.util-mt-40;
}

@mixin \.util-mt-48 {
  margin-top: 48px !important;
}

.util-mt-48 {
  @include \.util-mt-48;
}

@mixin \.util-pl-12 {
  padding-left: 12px;
}

.util-pl-12 {
  @include \.util-pl-12;
}

@mixin \.util-px-12 {
  padding-right: 12px;
  padding-left: 12px;
}

.util-px-12 {
  @include \.util-px-12;
}

@mixin \.util-px-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.util-px-16 {
  @include \.util-px-16;
}

@mixin \.util-px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.util-px-20 {
  @include \.util-px-20;
}

@mixin \.util-px-24 {
  padding-right: 24px;
  padding-left: 24px;
}

.util-px-24 {
  @include \.util-px-24;
}

@mixin \.util-px-32 {
  padding-right: 32px;
  padding-left: 32px;
}

.util-px-32 {
  @include \.util-px-32;
}

@mixin \.util-px-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.util-px-40 {
  @include \.util-px-40;
}

@mixin \.util-py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.util-py-12 {
  @include \.util-py-12;
}

@mixin \.util-py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.util-py-16 {
  @include \.util-py-16;
}

@mixin \.util-py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.util-py-20 {
  @include \.util-py-20;
}

@mixin \.util-py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.util-py-24 {
  @include \.util-py-24;
}

@mixin \.util-py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.util-py-32 {
  @include \.util-py-32;
}

@mixin \.util-py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.util-py-40 {
  @include \.util-py-40;
}

@mixin \.util-overflow-hidden {
  overflow: hidden;
}

.util-overflow-hidden {
  @include \.util-overflow-hidden;
}

@mixin \.util-overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.util-overflow-x {
  @include \.util-overflow-x;
}

@mixin \.util-overflow-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.util-overflow-y {
  @include \.util-overflow-y;
}

@mixin \.util-flex--gap24 {
  gap: 24px;
}

.util-flex--gap24 {
  @include \.util-flex--gap24;
}

@mixin \.util-flex--row-gap24 {
  row-gap: 24px;
}

.util-flex--row-gap24 {
  @include \.util-flex--row-gap24;
}

@mixin \.util-flex--column-gap24 {
  column-gap: 24px;
}

.util-flex--column-gap24 {
  @include \.util-flex--column-gap24;
}

@mixin \.util-flex--column-gap32 {
  column-gap: 32px;
}

.util-flex--column-gap32 {
  @include \.util-flex--column-gap32;
}

@mixin \.util-flex--gap20 {
  gap: 20px;
}

.util-flex--gap20 {
  @include \.util-flex--gap20;
}

@mixin \.util-flex--gap32 {
  gap: 32px;
}

.util-flex--gap32 {
  @include \.util-flex--gap32;
}

@mixin \.util-flex--gap48 {
  gap: 48px;
}

.util-flex--gap48 {
  @include \.util-flex--gap48;
}

@mixin \.util-flex--row-gap20 {
  row-gap: 20px;
}

.util-flex--row-gap20 {
  @include \.util-flex--row-gap20;
}

@mixin \.util-flex--row-gap32 {
  row-gap: 32px;
}

.util-flex--row-gap32 {
  @include \.util-flex--row-gap32;
}

@mixin \.util-flex--row-gap48 {
  row-gap: 48px;
}

.util-flex--row-gap48 {
  @include \.util-flex--row-gap48;
}

@mixin \.util-flex--column-gap20 {
  column-gap: 20px;
}

.util-flex--column-gap20 {
  @include \.util-flex--column-gap20;
}

@mixin \.util-flex--column-gap48 {
  column-gap: 48px;
}

.util-flex--column-gap48 {
  @include \.util-flex--column-gap48;
}

@mixin \.util-half-width {
  width: 50%;
}

.util-half-width {
  @include \.util-half-width;
}

@mixin \.util-px-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.util-px-8 {
  @include \.util-px-8;
}

@mixin \.util-py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.util-py-8 {
  @include \.util-py-8;
}

@mixin \.util-px-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.util-px-4 {
  @include \.util-px-4;
}

@mixin \.util-py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.util-py-4 {
  @include \.util-py-4;
}

@mixin \.util-pb-16 {
  padding-bottom: 16px;
}

.util-pb-16 {
  @include \.util-pb-16;
}

@mixin \.util-border-bottom-gray {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.util-border-bottom-gray {
  @include \.util-border-bottom-gray;
}

@mixin \.util-bg-neutral--white {
  background-color: #ffffff;
}

.util-bg-neutral--white {
  @include \.util-bg-neutral--white;
}

@mixin \.util-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.util-sticky {
  @include \.util-sticky;
}

@mixin \.util-font-neutral--light {
  color: $color-content-neutral--light;
}

.util-font-neutral--light {
  @include \.util-font-neutral--light;
}

.util-content-width {
  width: $content-width;
}

@mixin \.util-font-bold {
  font-weight: bold;
}

.util-font-bold {
  @include \.util-font-bold;
}

@mixin \.util-mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.util-mx-auto {
  @include \.util-mx-auto;
}

@media screen and (max-width: $mobile-view) {
  @mixin \.util-sp-full-width {
    width: 100% !important;
  }

  .util-sp-full-width {
    @include \.util-sp-full-width;
  }

  @mixin \.util-sp-flex--column {
    flex-direction: column;
  }

  .util-sp-flex--column {
    @include \.util-sp-flex--column;
  }

  @mixin \.util-sp-flex--align-center {
    align-items: center !important;
  }

  .util-sp-flex--align-center {
    @include \.util-sp-flex--align-center;
  }

  @mixin \.util-sp-mb-15 {
    margin-bottom: 15px !important;
  }

  .util-sp-mb-15 {
    @include \.util-sp-mb-15;
  }

  @mixin \.util-sp-mb-38 {
    margin-bottom: 38px !important;
  }

  .util-sp-mb-38 {
    @include \.util-sp-mb-38;
  }

  @mixin \.util-sp-py-16 {
    padding-top: 16px !important;
    padding-bottom: 24px !important;
  }

  .util-sp-py-16 {
    @include \.util-sp-py-16;
  }

  @mixin \.util-sp-py-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .util-sp-py-24 {
    @include \.util-sp-py-24;
  }

  @mixin \.util-sp-pt-16 {
    padding-top: 16px !important;
  }

  .util-sp-pt-16 {
    @include \.util-sp-pt-16;
  }

  @mixin \.util-sp-flex--gap16 {
    gap: 16px !important;
  }

  .util-sp-flex--gap16 {
    @include \.util-sp-flex--gap16;
  }

  @mixin \.util-sp-flex--gap24 {
    gap: 24px !important;
  }

  .util-sp-flex--gap24 {
    @include \.util-sp-flex--gap24;
  }

  @mixin \.util-sp-flex--gap40 {
    gap: 40px !important;
  }

  .util-sp-flex--gap40 {
    @include \.util-sp-flex--gap40;
  }
}
