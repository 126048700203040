@import "./mixins";
@import "./variables";

.breadcrumbs {
  display: flex;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.breadcrumbs__button-container {
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 150%;
}

.breadcrumbs__more-button {
  display: flex;
  align-items: center;
  padding: 4px;
  background: $color-background-primary--lightest-opacity-7;
  border: none;
  border-color: transparent;
  border-radius: 4px;
}

.breadcrumbs__more-button:hover {
  color: $color-content-primary--light;
  background-color: $color-background-primary--hover-faded;
}

.breadcrumbs__more-button:focus {
  outline: 0;
}

.breadcrumbs__more-button-icon {
  color: $color-content-primary--main;
}

.breadcrumbs__dropdown-container {
  box-sizing: border-box;
  width: fit-content;
  padding: 4px 0;
  background: $color-background-neutral--white;
  border-radius: 4px;
  box-shadow: $shadow-level-03;
}

.breadcrumbs__dropdown-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  padding: 4px 16px;
  font-size: $font-size-xs;
  line-height: 150%;
  color: $color-content-neutral--black;
  text-decoration: none;
  cursor: pointer;
}

.breadcrumbs__dropdown-option:hover {
  background: $color-background-primary--hover-faded;
}

.breadcrumb__item {
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 150%;
  color: $color-content-neutral--light;

  @include \.font-component-link-small;
}

.breadcrumb__item--current {
  display: flex;
  align-items: center;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.breadcrumb__item--link {
  display: flex;
  align-items: center;
  color: $color-content-primary--main;
  text-decoration: underline;
  cursor: pointer;
}

.breadcrumb__item--link:hover {
  text-decoration: none;
}
