@import "./mixins";
@import "./variables";

.shortcut {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 8px;
  color: $color-content-neutral--black;
  text-decoration: none;
  cursor: pointer;
  background: $color-background-neutral--white;
  border-radius: 8px;
  outline: $color-border-divider solid 1px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.shortcut--disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  background: $color-background-neutral--disabled;
}

.shortcut:hover {
  color: $color-content-neutral--black;
  text-decoration: none;
  background: $color-background-primary--hover-faded;
}

.shortcut:active {
  background: $color-background-primary--active;
}

.shortcut__right-area {
  display: inline-flex;
  gap: 8px;
  height: 100%;
}

.shortcut__icon {
  display: inline-flex;
  align-items: center;
  padding: 16px 0;
}

.shortcut__icon .icon {
  color: $color-content-primary--light;
}

.shortcut__text-main {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.shortcut__text {
  display: -webkit-box;
  width: 150px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.shortcut__text-bottom {
  display: flex;
  overflow: hidden;
  line-height: 150%;
  color: #cf4900;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.shortcut__text-container {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 147px;
  height: 100%;
  padding: 8px;
}

.shortcut__arrow-icon {
  color: #cf4900;
}

.shortcut--disabled .icon {
  color: $color-content-neutral--disabled;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}

@media screen and (max-width: $mobile-view) {
  .shortcut {
    width: 163.5px !important;
    height: 225px !important;
  }

  .shortcut__test {
    display: flex;
    flex-direction: column;
  }

  .shortcut__text-main {
    height: auto;
  }

  .shortcut__text {
    -webkit-line-clamp: 1;
    width: 120px;
    height: auto;
  }
}
