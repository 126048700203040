@import "./mixins";
@import "./variables";

.table-container {
  overflow: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  background-color: $color-background-neutral--white;
}

.table--fixed-layout {
  table-layout: fixed;
}

.table thead {
  position: sticky;
  top: 0;
}

.table thead .table__cell {
  position: relative;
  padding: 0 16px;
  color: $color-content-neutral--black;
  background: $color-background-primary--lightest;
  border-top: 1px solid $color-border-divider;

  @include \.font-component-table-header;
}

.table--condensed thead .table__cell {
  height: 40px;
}

.table--regular thead .table__cell {
  height: 48px;
}

.table--relaxed thead .table__cell {
  height: 56px;
}

.table thead .table__cell-inner {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.table thead .table__cell:last-child {
  border-right: none;
}

.table thead .table__row:last-child .table__cell {
  border-bottom: 1px solid $color-border-divider;
}

.table tbody .table__cell {
  padding: 0 16px;
  color: $color-content-neutral--black;
}

.table--condensed tbody .table__cell {
  height: 40px;
}

.table--regular tbody .table__cell {
  height: 48px;
}

.table--relaxed tbody .table__cell {
  height: 56px;
}

.table tbody:last-child .table__row:last-child .table__cell {
  border-bottom: 1px solid $color-border-divider;
}

.table tbody .table__cell:last-child {
  border-right: none;
}

.table tbody .table__row:nth-child(odd) .table__cell {
  background-color: $color-background-neutral--white;
}

.table tbody .table__row:nth-child(even) .table__cell {
  background-color: $color-background-neutral--lightest;
}

.table tbody .table__row.table__row--selected .table__cell {
  background-color: $color-background-primary--active;
}

.table tbody .table__row.table__row--error .table__cell {
  background-color: $color-background-error--lightest;
}

.table tbody .table__row:nth-child(odd):hover .table__cell {
  background-color: $color-background-neutral--hovered-white-line;
}

.table tbody .table__row:nth-child(even):hover .table__cell {
  background-color: $color-background-neutral--hover-faded;
}

.table tbody .table__row.table__row--selected:hover .table__cell {
  background-color: $color-background-primary--hovered-selected-line;
}

.table tbody .table__row.table__row--error:hover .table__cell {
  background-color: $color-background-error--hover-faded;
}

.table-shortcuts {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  min-height: 44px;
  padding: 10px;
  font-weight: $font-weight-bold;
  background: $color-background-primary--lightest;
  border-top: 1px solid $color-border-divider;
}

.table-shortcuts__buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.table-footer {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 8px;
  background: $color-background-primary--lightest;
  border-top: 1px solid $color-border-divider;
}

.table thead .table__cell:not(:last-child)::before {
  position: absolute;
  top: 25%;
  right: 0;
  width: 1px;
  height: 50%;
  content: "";
  background: $color-border-divider;
}

.table tbody .table__cell:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.table--overflowing .table__cell--sticky::after {
  display: block;
}

.table__cell {
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__cell--sticky-right {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.table-cell__tooltip {
  position: relative;
}

.table-cell__tooltip .tooltip-container {
  width: 100%;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__cell--medium {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  line-height: 130%;
}

.table__cell--small {
  font-size: $font-size-3xs;
  font-weight: $font-weight-bold;
  line-height: 150%;
}

.table__sort-btn .icon {
  color: #003d6c !important;
}

.table__sort-btn--inactive .icon {
  color: #777777 !important;
}

.table__filter-btn-container {
  position: relative;
}

.table__filter-btn .icon {
  color: #777777 !important;
}

.table__cell-inner-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__filter-container {
  display: flex;
  flex-direction: column;
  max-height: 164px;
  padding: 4px 0;
  margin-top: 8px;
  overflow-y: overlay;
  background-color: $color-background-neutral--white;
  border-radius: 4px;
  box-shadow: $shadow-level-03;
}

.table__cell--sticky {
  position: sticky !important;
  left: 0;
  z-index: 1;
}

.table__cell--sticky-right::before {
  position: absolute;
  top: 25%;
  left: 0;
  width: 1px;
  height: 50%;
  content: "";
  background: $color-border-divider;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  background-clip: content-box;
  border: 3px solid transparent;
}

::-webkit-scrollbar-track {
  background: none;
}

.table__cell--sticky::after {
  position: absolute;
  top: 0;
  right: -12px;
  display: none;
  width: 12px;
  height: 100%;
  pointer-events: none;
  content: "";
  background: linear-gradient(to right, rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0));
}

.table__cell .checkbox {
  line-height: 180%;
}

.table-double-column {
  border-top: 1px solid $color-border-divider;
  border-right: 1px solid $color-border-divider;
  border-left: 1px solid $color-border-divider;
}

.table-double-column .table-cell {
  height: 40px;
}

.table-double-column .table__cell .table__cell-head,
.table-double-column .table__cell .table__cell-data {
  padding: 9px 16px;
  font-size: 12px;
  border-bottom: 1px solid $color-border-divider;
}

.table-double-column .table__cell .table__cell-head {
  background: $color-background-primary--lightest;
  border-right: 0.5px solid $color-border-divider;
}
