.collapse {
  width: 100%;
  background: $color-background-neutral-sp--lightest;
  border-color: $color-border-divider;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.collapse--accordion:not(:first-child),
.collapse--accordion:not(:last-child) {
  border-radius: 0;
}

.collapse--accordion:first-child {
  border-radius: 4px 4px 0 0;
}

.collapse--accordion:last-child {
  border-radius: 0 0 4px 4px;
}

.collapse__header {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 12px 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.collapse--h4 .collapse__header {
  height: 40px;
}

.collapse__header-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: auto;
  pointer-events: none;
  background: none;
  border-radius: 50%;
}

.collapse__header-icon-container .icon {
  color: $color-content-neutral-sp--light;
}

.collapse__content {
  padding: 16px;
  background: $color-background-neutral--white;
  border-color: $color-border-divider;
  border-top-style: solid;
  border-top-width: 1px;
  border-radius: 0 0 4px 4px;
}

.collapse--is-open .collapse__header {
  border-radius: 4px 4px 0 0;
}

.collapse--section .collapse__header {
  font-size: 20px;
}
