@import "./mixins";
@import "./variables";

.sp-form-set {
  display: flex;
  width: 100%;
}

.sp-form-set--vertical {
  flex-direction: column;
}

.sp-form-set__label--base {
  padding-right: 8px;
  padding-left: 8px;
  background: $color-background-neutral--extra-bk-4;
}

.sp-form-set__helper-text {
  padding-top: 2px;
  font-size: $font-size-base;
  line-height: 145%;
  color: $color-content-neutral--light;
}

.sp-form-set__caption-massage--error {
  padding-top: 2px;
  font-size: $font-size-large;
  line-height: 150%;
  color: $color-content-error--main;
}

.sp-form-set__caption-massage--info {
  padding-top: 2px;
  font-size: $font-size-2xs;
  line-height: 145%;
  color: $color-content-neutral--light;
}

.sp-form-set__form {
  display: flex;
  flex: 1;
  align-items: center;
}

.sp-form-set__form--vertical {
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.sp-form-set .label {
  position: relative;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: start;
  height: auto;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  line-height: 150%;
  color: $color-content-neutral-sp--light;
  border-radius: 0;
}

.sp-form-set .label--small {
  padding: 2px 0;
}

.sp-form-set .label--medium {
  padding: 8px 0;
}

.sp-form-set .label--large {
  padding: 11px 0;
}

.sp-form-set .label--base {
  padding-right: 8px;
  padding-left: 8px;
  color: $color-content-neutral--black;
  background: $color-background-primary--lightest;
}

.sp-form-set .label__required-icon {
  font-size: 11px;
  line-height: 145%;
  color: $color-content-error--main;
}

.sp-form-set .label__help-container {
  width: 14px;
  height: 14px;
}

.sp-form-set .label__help-icon-container {
  position: absolute;
}

.sp-form-set .label__help-icon {
  color: $color-content-neutral--light;
}

.sp-form-set .label__option {
  @include \.font-component-link-small;

  font-weight: $font-weight-regular;
  color: $color-content-primary--main;
  text-align: right;
  text-decoration-line: underline;
}

.sp-form-set .label__required-badge {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  font-size: $font-size-2xs;
  line-height: 145%;
  color: $color-content-neutral--white;
  background: $color-background-error--main;
  border-color: transparent;
  border-radius: 100px;
}

.sp-form-set .label__checkbox .checkbox {
  margin-top: 0;
}

.sp-form-set .label__radio .radio {
  padding: 0;
}

.sp-form-set .label__option:hover {
  text-decoration: none;
}

.sp-form-set .label__required-badge::after {
  display: none;
}

.sp-form-set-label-gap--medium {
  gap: 8px;
}

.sp-form-set-label-gap--large {
  gap: 24px;
}

@media screen and (max-width: $mobile-view) {
  .sp-form-set--to-column {
    flex-direction: column;
    gap: 8px;
  }

  .sp-form-set--to-column .label {
    width: 100% !important;
  }
}
