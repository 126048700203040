@import "./mixins";
@import "./variables";

.tooltip-container {
  position: relative;
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.tooltip {
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 400px;
  padding: 4px 8px;
  overflow: visible;
  color: $color-content-neutral--white;
  background-color: $color-background-neutral--extra-bk-80;
  border-color: transparent;
  border-radius: 4px;

  @include \.font-component-tooltip;
}

.tooltip::after {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
}

.tooltip--under::after {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: $color-background-neutral--extra-bk-80;
}

.tooltip--top::after {
  top: 99.8%;
  left: 50%;
  margin-left: -5px;
  border-top-color: $color-background-neutral--extra-bk-80;
}

.tooltip--right::after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-right-color: $color-background-neutral--extra-bk-80;
}

.tooltip--left::after {
  top: 50%;
  left: 99.8%;
  margin-top: -5px;
  border-left-color: $color-background-neutral--extra-bk-80;
}

.tooltip--topLeft::after {
  top: 99.8%;
  left: 8px;
  border-top-color: $color-background-neutral--extra-bk-80;
}

.tooltip--topRight::after {
  top: 99.8%;
  right: 8px;
  border-top-color: $color-background-neutral--extra-bk-80;
}

.tooltip--underLeft::after {
  top: -10px;
  left: 8px;
  border-bottom-color: $color-background-neutral--extra-bk-80;
}

.tooltip--underRight::after {
  top: -10px;
  right: 8px;
  border-bottom-color: $color-background-neutral--extra-bk-80;
}
