@import "./mixins";
@import "./variables";

.toggle-button-container {
  display: flex;
  width: 100%;
}

.toggle-button-set {
  display: flex;
  gap: 8px;
  border-radius: 4px;
}

.toggle-button-set--negative {
  border-color: $color-border-neutral--white;
}

.toggle-button {
  position: relative;
  flex: 1 0;
  min-width: 1px;
  height: 48px;
  padding: 6px;
  cursor: pointer;
  border: none;
  border-radius: 8px 8px 0 0;
}

.toggle-button--full-width {
  width: 100%;
}

.toggle-button:disabled {
  pointer-events: none;
  box-shadow: none;
}

.toggle-button--large {
  @include \.font-component-button-large;

  min-height: 40px;
  padding: 8px;
}

.toggle-button--medium {
  @include \.font-component-button-medium;

  min-height: 32px;
  padding: 5px 4px;
}

.toggle-button--small {
  @include \.font-component-button-small;

  min-height: 24px;
  padding: 4px 0;
}

.toggle-button:focus {
  outline: 0;
}

.toggle-button__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  white-space: nowrap;
}

.toggle-button--regular {
  color: $color-user-primary--main;
  background-color: $color-background-neutral--white;
  border: 1px solid $color-border-outline;
  border-bottom: 0;
}

.toggle-button--regular:not(.toggle-button--selected, :last-child)::after {
  position: absolute;
  top: 20%;
  right: -1px;
  z-index: 1;
  height: 60%;
  content: "";
}

.toggle-button--regular.toggle-button--selected {
  color: $color-content-neutral--white;
  pointer-events: none;
  background-color: $color-user-primary--main;
  border: 1px solid $color-user-primary--main;
}

.toggle-button--regular:active {
  color: $color-content-neutral--white;
  background-color: $color-user-primary--main;
}

.toggle-button--regular:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--disabled;
}

.toggle-button--negative {
  color: $color-content-neutral--white;
  background-color: $color-user-primary--main;
}

.toggle-button--negative:not(.toggle-button--selected, :last-child)::after {
  position: absolute;
  top: 20%;
  right: -1px;
  z-index: 1;
  width: 1px;
  height: 60%;
  content: "";
  background: $color-border-neutral--white;
}

.toggle-button--negative.toggle-button--selected {
  color: $color-user-primary--main;
  pointer-events: none;
  background-color: $color-background-neutral--white;
}

.toggle-button--negative:hover {
  background-color: $color-background-primary--hover;
}

.toggle-button--negative:active {
  color: $color-user-primary--main;
  background-color: $color-background-neutral--white;
}

.toggle-button--negative:disabled {
  color: $color-content-neutral--disabled;
  background-color: $color-background-neutral--disabled;
}
