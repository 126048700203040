@import "./mixins";
@import "./variables";

.react-datepicker-wrapper {
  width: 100%;
}

.date-picker__input {
  position: relative;
  display: flex;
  align-items: center;
  width: 208px;
  height: 40px;
  font-size: $font-size-xl;
  line-height: 150%;
}

.range .date-picker__input {
  background-color: $color-background-neutral--white;
  border: none;
  border-radius: 4px;
  outline: 1px solid $color-background-neutral-sp--light;
}

.range .tilde {
  color: $color-content-neutral--disabled;
}

.date-picker__input--calendar-icon {
  position: absolute;
  right: 8px;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  color: $color-content-neutral--light;
  pointer-events: none;
}

.date-picker__input--error-icon {
  position: absolute;
  right: 30px;
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.range .date-picker__input--error-icon {
  right: 32px;
}

.date-picker__input--error-icon .icon {
  color: $color-content-error--main;
}

.date-picker__input input {
  flex: 1;
  width: 100%;
  height: 40px;
  padding: 8px;
  color: $color-content-neutral--black;
  background-color: $color-user-secondary-not-entered;
  border: none;
  border-radius: 4px;
  outline: $color-background-neutral-sp--light solid 1px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
}

.date-picker__input--filled input {
  background-color: $color-background-neutral--white;
}

.date-picker__input input:hover {
  outline-color: $color-border-neutral--light;
}

.date-picker__input input:focus {
  outline-width: 1px;
  outline-color: $color-border-primary--main;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset,
    0 0 6px 0 rgba(237, 84, 0, 0.6);
}

.date-picker__input input:disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  background-color: $color-background-neutral--disabled;
}

.date-picker__input input:disabled + .date-picker__input--calendar-icon .icon {
  color: $color-content-neutral--disabled;
}

.date-picker__input input::placeholder {
  color: $color-content-neutral--disabled;
}

.date-picker__input--small {
  height: 32px;
}

.date-picker__input--small input {
  height: 32px;
  font-size: $font-size-small;
}

.date-picker__input--status-error input {
  outline-color: $color-border-error--main;
}

.range .date-picker__input input {
  width: 140px;
  height: 36px;
  border-style: none;
  outline: none;
}

.range .date-picker__input--small input {
  height: 32px;
}

.range .date-picker__input--status-error {
  outline-color: $color-border-error--main;
}

.range .date-picker__input--disabled {
  color: $color-background-neutral--disabled;
  pointer-events: none;
  background-color: $color-background-neutral--disabled;
  outline-color: $color-content-neutral--disabled;
}

.date-picker .vc-popover-content-wrapper {
  --popover-horizontal-content-offset: 1px;
  --popover-vertical-content-offset: 1px;
}

.date-picker {
  --rounded-full: 4px;

  font-family: "BIZ UDPGothic";
  border-style: none;
  border-radius: 4px;
  box-shadow: $shadow-level-03;
}

.date-picker .react-datepicker__day-name {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  color: $color-content-neutral--black;
}

.date-picker .react-datepicker__day-name:first-child {
  color: $color-content-error--main;
}

.date-picker .react-datepicker__day-name:nth-child(7) {
  color: $color-content-information--main;
}

.date-picker
  .react-datepicker__week
  .react-datepicker__day:not(
    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled
  ):first-child {
  color: $color-content-error--main;
}

.date-picker
  .react-datepicker__week
  .react-datepicker__day:not(
    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled
  ):last-child {
  color: $color-content-information--main;
}

.date-picker .react-datepicker__day--outside-month {
  color: $color-content-neutral-sp--disable;
}

.date-picker__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}

.date-picker__input--status-error input:hover {
  outline-color: $color-border-error--main;
}

.range .date-picker__input--status-error:hover {
  outline-color: $color-border-error--main;
}

.date-picker__date {
  border-radius: 4px;
}

.react-datepicker__day--today .date-picker__date {
  font-weight: 400;
  color: $color-border-error--main;
  border: 1px solid $color-border-error--main;
  outline-color: $color-border-error--main;
}

.react-datepicker__day--disabled.react-datepicker__day--today
  .date-picker__date {
  color: $color-content-neutral-sp--disable;
  border: 1px solid $color-content-neutral-sp--disable;
}

.date-picker
  .react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  background-color: $color-background-primary--hover-faded;
}

.select-month {
  position: relative;
}

.select-month__content-background {
  position: fixed;
  inset: 0;
  background-color: transparent;
}

.select-month_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  padding: 3px 8px;
  background: rgba(0, 0, 0, 0.06);
  border: none;
  border-radius: 4px;
}

.select-month_nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-month__item-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px 5px;
}

.select-month_popover-container {
  position: absolute;
  left: -30px;
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $color-content-neutral--white;
  background-color: #2d3748;
  border: 1px solid;
  border-color: #4a5568;
  border-radius: 0.5em;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.select-month__item {
  width: 48px;
  padding: 4px 0;
  font-weight: 600;
  line-height: 1.375;
  color: $color-content-neutral--white;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 0.25rem;
}

.select-month__item.is-current {
  font-weight: 700;
  color: #ebf8ff;
  border-color: #ebf8ff;
}

.select-month__item.is-active {
  font-weight: 700;
  color: #2a4365;
  background-color: #ebf8ff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.select-month__item:not(.is-active, .is-disabled):hover {
  background: #1a202c;
}

.select-month__item.is-disabled {
  opacity: 0.4;
}

.react-datepicker__header {
  padding: 0;
  padding-bottom: 0;
  background-color: $color-content-neutral--white;
  border: none;
}

.date-picker .react-datepicker__day--selected .date-picker__date {
  width: 32px;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--main;
}

.date-picker .react-datepicker__day--selected .date-picker__date:hover {
  background-color: $color-background-primary--main;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 32px;
  margin: 4px;
  line-height: 32px;
}

.date-picker
  .react-datepicker__day--selected.react-datepicker__day--in-selecting-range {
  width: 32px;
  padding-right: 4px;
  margin-right: 0;
  margin-left: 4px;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker__day--selected.react-datepicker__day--in-range {
  width: 32px;
  margin-right: 0;
  margin-left: 4px;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker__day--in-range {
  width: 36px;
  margin: 4px 0;
  color: $color-content-primary--accent;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker__day--in-selecting-range {
  width: 36px;
  margin: 4px 0;
  color: $color-content-primary--main;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker__day--range-end .date-picker__date {
  width: 32px;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--main;
}

.date-picker .react-datepicker__day--range-end .date-picker__date:hover {
  color: $color-content-neutral--white;
  background-color: $color-background-primary--main;
}

.date-picker .react-datepicker__day--selecting-range-end .date-picker__date {
  color: $color-content-neutral--white;
  background-color: $color-background-primary--main;
}

.date-picker
  .react-datepicker__day--selecting-range-end
  .date-picker__date:hover {
  color: $color-content-neutral--white;
  background-color: $color-background-primary--main;
}

.date-picker
  .react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range {
  width: 32px;
  padding-left: 4px;
  margin-right: 4px;
  margin-left: 0;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker__day--range-end.react-datepicker__day--in-range {
  width: 32px;
  padding-left: 4px;
  margin-right: 4px;
  margin-left: 0;
  color: $color-content-neutral--white;
  background-color: $color-background-primary--hover-faded;
  border-radius: 0;
}

.date-picker .react-datepicker-time__caption {
  display: none;
}

.date-picker .time-picker__container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.date-picker .react-datepicker__input-time-container {
  padding: 8px;
  margin: 0;
  border-top: 1px solid $color-border-divider;
}

.date-picker__input--borderless {
  outline: none !important;
  outline-color: transparent !important;
  box-shadow: none !important;
}

.date-picker__header-date {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 1.5px;
  font-weight: 700;
}

.date-picker__input--borderless:focus-within {
  border-radius: 0;
}

.date-picker__input--borderless input {
  outline: none !important;
  outline-color: transparent !important;
  box-shadow: none !important;
}

.date-picker__input--borderless input:disabled {
  color: $color-background-neutral--disabled;
  pointer-events: none;
  background-color: $color-background-neutral--disabled;
  outline-color: $color-content-neutral--disabled;
}

.range .date-picker__input--borderless input:disabled {
  pointer-events: none;
  background-color: $color-background-neutral--disabled;
}

.date-picker__input--small .date-picker__input--calendar-icon {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  color: $color-content-neutral--light;
  pointer-events: none;
}

.range .date-picker__input--calendar-icon {
  right: 12px;
}

.range .date-picker__input:focus-within {
  outline-width: 2px;
  outline-color: $color-border-primary--main;
}

.date-picker__input--status-error input:focus {
  outline-width: 2px;
  outline-color: $color-border-error--main;
}

.date-picker__input--medium input {
  height: 32px;
  padding: 8px 12px;
}

.datepicker__arrow-button {
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;
}

.datepicker__arrow-button:focus {
  border: none;
  outline: none;
}

.date-picker__input--large input {
  height: 38px;
  padding: 12px;
}

.date-picker__input--status-error input:disabled {
  outline-color: $color-content-neutral--disabled;
}

.range .date-picker__input--status-error:focus-within {
  outline-width: 2px !important;
  outline-color: $color-border-error--main;
}

.date-picker__input--status-error:focus-within {
  outline-width: 2px !important;
  outline-color: $color-border-error--main;
}

.date-picker__input--borderless.date-picker__input--status-error:focus-within::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: $color-border-error--main;
}

.date-picker__input--borderless:focus-within::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: $color-border-primary--main;
}

.react-datepicker__month {
  margin: 16px;
  margin-top: 4px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: none;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background: none;
}

.react-datepicker-popper {
  position: fixed !important;
  z-index: 999;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 2px;
}
