@import "./mixins";
@import "./variables";

.notification {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 8px 8px 8px 16px;
  font-size: $font-size-small;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-left-width: 4px;
}

.notification--error {
  background-color: $color-background-error--lightest;
  border-color: $color-border-error--light;
  border-left-color: $color-border-error--main;
}

.notification--warning {
  background-color: $color-background-warning--lightest;
  border-color: $color-border-warning--light;
  border-left-color: $color-border-warning--main;
}

.notification--information {
  background-color: $color-background-information--lightest;
  border-color: $color-border-information--light;
  border-left-color: $color-border-information--main;
}

.notification--success {
  background-color: $color-background-success--lightest;
  border-color: $color-border-success--light;
  border-left-color: $color-border-success--main;
}

.notification__description {
  margin-top: 4px;
  font-size: $font-size-xs;
  line-height: 18px;
}

.notification__textArea {
  margin-top: 2px;
  margin-bottom: 2px;
}

.notification__text {
  line-height: 20px;
}

.notification__detail {
  font-size: $font-size-xs;
}

.notification__detail-button {
  margin-top: 4px;
}

.notification__detail-content {
  margin-top: 4px;
}

.notification__timestamp {
  margin-top: 4px;
  font-size: $font-size-2xs;
  line-height: 16px;
  color: $color-content-neutral--light;
}

.notification__upper-right-buttons {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  margin-left: auto;
}

.notification__close-button {
  margin-left: 8px;
}

.notification--snackbar {
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  box-shadow: $shadow-level-02;
}

.notification__icon {
  margin-top: 3px;
  margin-right: 8px;
}

.notification--error .notification__icon .icon {
  color: $color-content-error--main;
}

.notification--warning .notification__icon .icon {
  color: $color-content-warning--main;
}

.notification--information .notification__icon .icon {
  color: $color-content-information--main;
}

.notification--success .notification__icon .icon {
  color: $color-content-success--main;
}
