@import "./mixins";
@import "./variables";

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.list-item {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  background: $color-background-neutral--white;
  border-bottom: 1px solid $color-border-divider;
  // changeEmp
  cursor: pointer;
}

.list-item__left,
.list-item__center,
.list-item__right {
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.list-item__center {
  gap: 8px;
  width: 100%;
}

.list-item__right {
  align-self: center;
}

.list-item__right--top {
  align-self: flex-start;
}

.list-item--selected {
  background: $color-background-primary--active;
}

.list-item--narrow {
  padding: 8px 16px;
}

.list-item--no-border {
  border-bottom: 0;
}

.bullet-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.bullet-list--item {
  display: flex;
  gap: 9px;
}
