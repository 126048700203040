@import "./mixins";
@import "./variables";

.tag {
  position: relative;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  height: 20px;
  padding: 2px 8px;
  border-radius: 100px;
}

.tag--disabled,
.tag--disabled-emphasis {
  pointer-events: none;
}

.tag--clickable {
  cursor: pointer;
  border-radius: 4px;
}

.tag__icon {
  display: flex;
  align-items: center;
}

.tag--disabled.tag--clickable {
  color: $color-content-neutral--light;
  background: $color-background-neutral--disabled;
  border-color: $color-border-neutral--disabled;
  border-style: solid;
  border-width: 1px;
}

.tag--disabled-emphasis.tag--clickable {
  color: $color-content-neutral--white;
  background: $color-content-neutral--disabled;
}

.tag--disabled.tag--clickable .tag__icon .icon {
  color: $color-content-neutral--light;
}

.tag--disabled-emphasis.tag--clickable .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--default {
  color: $color-content-primary--accent;
  background: $color-background-neutral--light;
  border-color: $color-border-primary--light;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--default:hover {
  background: $color-background-primary--hover-faded;
}

.tag--clickable.tag--default:active {
  color: $color-content-primary--main;
  background: $color-background-primary--active;
  border-color: $color-border-primary--light;
  border-style: solid;
  border-width: 1px;
}

.tag--default .tag__icon .icon {
  color: $color-content-primary--main;
}

.tag--default-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-primary--main;
}

.tag--clickable.tag--default-emphasis:hover {
  background: $color-background-primary--hover;
}

.tag--clickable.tag--default-emphasis:active {
  background: $color-background-primary--selected;
}

.tag--default-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--error {
  color: $color-content-error--main;
  background: $color-background-error--lightest;
  border-color: $color-border-error--light;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--error:hover {
  background: $color-background-error--hover-faded;
}

.tag--clickable.tag--error:active {
  background: $color-background-error--active;
}

.tag--error .tag__icon .icon {
  color: $color-content-error--main;
}

.tag--error-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-error--main;
}

.tag--clickable.tag--error-emphasis:hover {
  background: $color-background-error--hover;
}

.tag--clickable.tag--error-emphasis:active {
  background: $color-background-error--selected;
}

.tag--error-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--warning {
  color: $color-content-warning--dark;
  background: $color-background-warning--lightest;
  border-color: $color-border-warning--main;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--warning:hover {
  background: $color-background-warning--hover-faded;
}

.tag--clickable.tag--warning:active {
  background: $color-background-warning--active;
}

.tag--warning .tag__icon .icon {
  color: $color-content-warning--dark;
}

.tag--warning-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-warning--dark;
}

.tag--clickable.tag--warning-emphasis:hover {
  background: $color-background-warning--hover;
}

.tag--clickable.tag--warning-emphasis:active {
  background: $color-background-warning--selected;
}

.tag--warning-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--information {
  color: $color-content-information--main;
  background: $color-background-information--lightest;
  border-color: $color-border-information--light;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--information:hover {
  background: $color-background-information--hover-faded;
}

.tag--clickable.tag--information:active {
  background: $color-background-information--active;
}

.tag--information .tag__icon .icon {
  color: $color-content-information--main;
}

.tag--information-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-information--main;
}

.tag--clickable.tag--information-emphasis:hover {
  background: $color-background-information--hover;
}

.tag--clickable.tag--information-emphasis:active {
  background: $color-background-information--selected;
}

.tag--information-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--success {
  color: $color-content-success--main;
  background: $color-background-success--lightest;
  border-color: $color-border-success--light;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--success:hover {
  background: $color-background-success--hover-faded;
}

.tag--clickable.tag--success:active {
  background: $color-background-success--active;
}

.tag--success .tag__icon .icon {
  color: $color-content-success--main;
}

.tag--success-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-success--main;
}

.tag--clickable.tag--success-emphasis:hover {
  background: $color-background-success--hover;
}

.tag--clickable.tag--success-emphasis:active {
  background: $color-background-success--selected;
}

.tag--success-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--neutral {
  color: $color-content-neutral--black;
  background: $color-background-neutral--lightest;
  border-color: $color-border-outline;
  border-style: solid;
  border-width: 1px;
}

.tag--clickable.tag--neutral:hover {
  background: $color-background-neutral--hover-faded;
}

.tag--clickable.tag--neutral:active {
  background: $color-background-neutral--active;
}

.tag--neutral .tag__icon .icon {
  color: $color-content-neutral--black;
}

.tag--neutral-emphasis {
  color: $color-content-neutral--white;
  background: $color-background-neutral--dark;
}

.tag--clickable.tag--neutral-emphasis:hover {
  background: $color-background-neutral--hover;
}

.tag--clickable.tag--neutral-emphasis:active {
  background: $color-background-neutral--selected;
}

.tag--neutral-emphasis .tag__icon .icon {
  color: $color-content-neutral--white;
}

.tag--input {
  box-sizing: border-box;
  background: $color-background-neutral--lightest;
  border-color: $color-border-outline;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.tag--input__close-button {
  height: 16px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
}

.tag--disabled.tag--input__close-button {
  height: 16px;
  padding: 0;
  border: none;
  outline: none;
}

.tag--input__close-button:hover {
  background: $color-background-neutral--hover-faded;
  border: none;
}

.tag--input__close-button:active {
  background: $color-background-neutral--active;
}

.tag--disabled.tag--input {
  color: $color-content-neutral--disabled;
  background: $color-background-neutral--lightest;
  border-radius: 2px;
  outline-color: $color-border-divider;
}

.tag--input__close-icon {
  cursor: pointer;
}

.tag--disabled .tag--input__close-icon {
  color: $color-content-neutral--disabled;
  pointer-events: none;
}

.tag--switch {
  position: relative;
  gap: 0;
  margin: 0;
  cursor: pointer;
  border-color: $color-border-outline;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}

.tag--disabled.tag--switch {
  color: $color-content-neutral--disabled;
}

.tag--switch__input {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.tag--switch__input::before {
  position: absolute;
  top: 0;
  left: 8px;
  font-family: "Material Icons";
  color: $color-content-neutral--light;
  cursor: pointer;
  content: "\e9ef";
}

.tag--disabled .tag--switch__input::before {
  color: $color-content-neutral--disabled;
}

.tag--switch__input:checked::before {
  color: $color-content-neutral--white;
  cursor: pointer;
  content: "\e5ca";
}

.tag--switch__label {
  padding-left: 18px;
  font-size: $font-size-2xs;
  line-height: 145%;
  text-decoration-line: underline;
}

.tag--switch__input:checked + .tag--switch__label {
  color: $color-content-neutral--white;
}

.tag__label {
  overflow: hidden;
  font-size: $font-size-2xs;
  line-height: 145%;
  text-overflow: ellipsis;
}

.tag--clickable__label {
  font-size: $font-size-2xs;
  line-height: 145%;
  text-decoration-line: underline;
}

.tag--switch.tag--checked {
  background: $color-background-primary--main;
  outline: none;
}

.tag--disabled.tag--switch.tag--checked {
  background: $color-content-neutral--disabled;
  border-color: transparent;
  outline: none;
}

.tag--switch:hover {
  background: $color-background-neutral--hover-faded;
}

.tag--switch.tag--checked:hover {
  background: $color-background-primary--hover;
}

.tag--new {
  color: $color-background-neutral--white;
  background: #0076a8;
  border: 0 solid $color-background-neutral--white;
  border-radius: 8px;
}

.tag-size--large {
  font-size: $font-size-base;
}
