@import "./mixins";
@import "./variables";

.popover {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 50;
  overflow: visible;
  color: $color-content-neutral--black;
}

.popover__item-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.popover--with-interaction.popover-open-enter-active {
  animation: bounce-in 0.2s;
}

.popover--with-interaction.popover-open-leave-active {
  animation: bounce-out 0.2s;
}

@keyframes bounce-in {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }

  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes bounce-out {
  0% {
    transform: scaleY(1);
    transform-origin: top;
  }

  100% {
    transform: scaleY(0);
    transform-origin: top;
  }
}

.popover--with-shadow {
  padding: 4px 0;
  background-color: $color-background-neutral--white;
  border-radius: 4px;
  box-shadow: $shadow-level-03;
}

.popover--with-tail::before {
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0 8px 8px;
}

.popover--with-tail.popover--under::before {
  top: -8px;
  left: calc(50% - 8px);
  border-width: 0 8px 8px;
  border-bottom-color: $color-background-neutral--white;
}

.popover--with-tail.popover--top::before {
  bottom: -8px;
  left: calc(50% - 8px);
  border-width: 8px 8px 0;
  border-top-color: $color-background-neutral--white;
}

.popover--with-tail.popover--left::before {
  top: calc(50% - 8px);
  right: -8px;
  border-width: 8px 0 8px 8px;
  border-left-color: $color-background-neutral--white;
}

.popover--with-tail.popover--right::before {
  top: calc(50% - 8px);
  left: -8px;
  border-width: 8px 8px 8px 0;
  border-right-color: $color-background-neutral--white;
}

.popover--with-tail.popover--topLeft::before {
  bottom: -8px;
  left: 8px;
  border-width: 8px 8px 0;
  border-top-color: $color-background-neutral--white;
}

.popover--with-tail.popover--topRight::before {
  right: 8px;
  bottom: -8px;
  border-width: 8px 8px 0;
  border-top-color: $color-background-neutral--white;
}

.popover--with-tail.popover--underLeft::before {
  top: -8px;
  left: 8px;
  border-width: 0 8px 8px;
  border-bottom-color: $color-background-neutral--white;
}

.popover--with-tail.popover--underRight::before {
  top: -8px;
  right: 8px;
  border-width: 0 8px 8px;
  border-bottom-color: $color-background-neutral--white;
}
