@import "variables";

.image-gallery--selected {
  padding: 1px;
  border: 2px solid $color-border-primary--main;
  border-radius: 4px;
}

.image-gallery--displayed {
  border-radius: 4px;
}
