@import "./mixins";
@import "./variables";

.information-dialog {
  position: relative;
  display: flex;
  padding: 24px;
  overflow: hidden;
  background: $color-background-neutral--white;
  border: none;
  border-radius: 4px;
  box-shadow: $shadow-level-04;
}

.information-dialog::backdrop {
  background: $color-background-neutral--extra-bk-50;
}

.information-dialog__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
}

.information-dialog__icon-content-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.information-dialog--error .information-dialog__alert-level-icon .icon {
  color: $color-content-error--main;
}

.information-dialog--warning .information-dialog__alert-level-icon .icon {
  color: $color-content-warning--main;
}

.information-dialog__content-container {
  width: 100%;
  height: 100%;
}

.information-dialog__close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.information-dialog__title {
  display: flex;
  align-items: center;
  padding-right: 24px;
  margin-bottom: 16px;
  font-size: $font-size-1xl;
  font-weight: $font-weight-bold;
  line-height: 24px;
  color: $color-content-neutral--black;
}

.information-dialog__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.information-dialog[open] {
  animation: fadeIn 300ms ease 0s 1 normal;
}

.information-dialog[open]::backdrop {
  animation: fadeIn 150ms ease 0s 1 normal;
}

.information-dialog.close {
  animation: fadeOut 150ms ease 0s 1 normal forwards;
}

.information-dialog.close::backdrop {
  animation: fadeOut 150ms ease 0s 1 normal forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
