@import "./mixins";
@import "./variables";

.footer-container {
  bottom: 0;
  width: 100%;
  overflow-x: hidden;
}

.footer {
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 108px;
  color: #ffffff;
  background-image: url("../assets/footer-pc.png");
  background-size: cover;
}

.footer__content {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 150%;
}

.footer--top {
  left: 0;
  width: 100%;
  background-blend-mode: soft-light;
}

.footer__divider {
  display: none;
}

.footer__link {
  width: 100%;
  font-size: 16px;
}

.footer__pc {
  padding: 16px;
  padding-left: 108px;
}

.footer__sp {
  display: none !important;
}

.footer__content-sp {
  display: none !important;
}

.footer-button {
  width: 100%;
  background-color: $color-background-neutral--white;
}

.footer-button--scrollable {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.footer-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  box-shadow: 0 -3px 12px 0 rgba(0, 0, 0, 0.14);
}

.footer__copyright {
  padding: 16px;
  padding-right: 108px;
}

@media screen and (max-width: $mobile-view) {
  .footer {
    height: 196px;
    background-image: url("../assets/footer-sp.png");

    // padding: 8px 20px !important;
  }

  .footer--content {
    font-size: $font-size-large;
    font-weight: $font-weight-regular;
    line-height: 160%;
  }

  .footer__control {
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .footer__copyright {
    display: none !important;
  }

  .footer__divider {
    display: flex !important;
  }

  .footer__sp {
    display: flex !important;
    gap: 8px;
    padding: 16px;
  }

  .footer__pc {
    display: none !important;
  }

  .footer__content-sp {
    z-index: 5;
    display: flex !important;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 53px;
    color: #ffffff;
    border-top: 1px solid white;
  }

  .footer__copyright-sp {
    justify-content: center !important;
  }

  .footer__sp-top {
    padding: 8px 0;
  }

  .footer__sp-top .link--wrapper,
  .footer__sp-bottom .link--wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    text-align: center;
  }

  .footer__sp-bottom {
    padding: 8px 0;
  }

  .footer-button {
    width: 100%;
  }
}
