.layout-box {
  display: flex;
}

.layout-box--column {
  flex-direction: column;
}

.layout-box--gap-none {
  gap: 0;
}

.layout-box--gap-1_2x {
  gap: 4px;
}

.layout-box--gap-1x {
  gap: 8px;
}

.layout-box--gap-10px {
  gap: 10px;
}

.layout-box--gap-2x {
  gap: 16px;
}

.layout-box--gap-3x {
  gap: 24px;
}

.layout-box--gap-4x {
  gap: 32px;
}

.layout-box--gap-5x {
  gap: 40px;
}

.layout-box--gap-7x {
  gap: 56px;
}

.layout-box--row-gap-1_2x {
  row-gap: 4px;
}

.layout-box--row-gap-1x {
  row-gap: 8px;
}

.layout-box--row-gap-2x {
  row-gap: 16px;
}

.layout-box--row-gap-3x {
  row-gap: 24px;
}

.layout-box--row-gap-4x {
  row-gap: 32px;
}

.layout-box--row-gap-5x {
  row-gap: 40px;
}

.layout-box--align-start {
  align-items: flex-start;
}

.layout-box--align-center {
  align-items: center;
}

.layout-box--align-end {
  align-items: flex-end;
}

.layout-box--align-stretch {
  align-items: stretch;
}

.layout-box--justify-between {
  justify-content: space-between;
}

.layout-box--justify-around {
  justify-content: space-around;
}

.layout-box--justify-start {
  justify-content: flex-start;
}

.layout-box--justify-center {
  justify-content: center;
}

.layout-box--justify-end {
  justify-content: flex-end;
}

.layout-box--wrap {
  flex-wrap: wrap;
}

.layout-box--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.layout-box--full-width {
  width: 100%;
}

.layout-box--full-height {
  height: 100%;
}

.layout-box--overflow {
  overflow: auto;
}

.layout-box--flex-shrink {
  flex-shrink: 0;
}

@media screen and (max-width: $mobile-view) {
  .product-list {
    row-gap: 16px !important;
  }

  .layout-box {
    min-width: 0 !important;
  }

  .layout-box--to-column {
    flex-direction: column;
  }
}
