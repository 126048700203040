@import "./mixins";
@import "./variables";

.order-history {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 1064px;
  color: $color-user-neutral-black;
}

.order-history-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: $color-background-neutral--white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.order-history--top {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  padding: 16px;
  font-size: $font-size-large;
  background: $color-background-neutral-sp--lightest;
  border-radius: 8px 0 0 8px;
}

.order-history--top-left {
  width: 100%;
  overflow: hidden;
  color: $color-content-neutral-sp--light;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.order-history--top-right {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 16px;
}

.order-history--top-right span:nth-child(2) {
  font-weight: $font-weight-bold;
}

.order-history-content {
  display: flex;
  flex-direction: column;
  gap: 17px;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px;
}

.order-history-content span {
  font-size: $font-size-xl;
  font-weight: $font-weight-bold;
}

.order-history-button {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
}

.order-history-button--left,
.order-history-button--right {
  width: 100%;
}

.order-history-images {
  display: flex;
  gap: 8px;
  align-items: center;
}

.order-history--image,
.order-history--image-empty {
  width: 128px;
  height: 128px;
  border-radius: 8px;
}

.order-history--image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.order-history--image-empty {
  background-color: #cccccc;
}

@media only screen and (max-width: $mobile-view) {
  .order-history {
    width: 100%;
    min-width: 100%;
  }

  .order-history .order-history--top {
    flex-direction: column;
    gap: 4px;
  }

  .order-history .order-history--top-right {
    margin-left: 0;
  }
}

.order-history-button .sp-btn__inner {
  max-width: 1px;
}

@media only screen and (max-width: 400px) {
  .order-history__mobile .order-history-button button {
    width: 152px !important;
  }
}
