@import "./mixins";
@import "./variables";

.link a {
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}

.link .link__content {
  text-decoration: underline;
}

.link a:hover {
  text-decoration: none;
}

.link .icon {
  margin-right: 4px;
  color: $color-content-primary--main;
}

.link .icon:last-child {
  margin-left: 6px;
}

.link .icon.external-link-icon {
  margin-left: 6px;
}

.link--color-white a {
  color: $color-content-neutral--white;
}

.link--color-primary a {
  color: $color-user-primary--main;
}

.link--large {
  @include \.font-component-link-large;
}

.link--medium {
  @include \.font-component-link-medium;
}

.link--small {
  @include \.font-component-link-small;
}

.link--xl {
  font-size: $font-size-xl;
}

.link--5xl {
  font-size: $font-size-5xl;
}

.link--4xl {
  font-size: $font-size-4xl;
}

.link--3xl {
  font-size: $font-size-3xl;
}

.link--2xl {
  font-size: $font-size-2xl;
}

.link--1xl {
  font-size: $font-size-1xl;
}

.link--disabled a {
  color: $color-content-neutral--disabled;
  pointer-events: none;
}

.link--disabled .icon {
  color: $color-content-neutral--disabled;
}
