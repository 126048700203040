@import "./variables";

.simple-card {
  display: flex;
  width: 170px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.simple-card--primary {
  background-color: $color-background-primary--lightest;
  border: 1px solid $color-border-primary--main;
}

.simple-card--secondary {
  background-color: $color-background-neutral--white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}
