.content-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $color-background-neutral--white;
  box-shadow: $shadow-level-02top;
}

.content-footer--fixed {
  position: fixed;
}

.content-footer__left,
.content-footer__center,
.content-footer__right {
  display: flex;
  gap: 8px;
  align-items: center;
}
