@import "./mixins";
@import "./variables";

::-ms-reveal {
  display: none;
}

.input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.input__input-area {
  width: 100%;
  height: 44px;
  color: $color-content-neutral--black;
  background-color: $color-user-secondary-not-entered;
  border-color: $color-content-neutral-sp--lightest;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
}

.input--small {
  font-size: $font-size-small;
}

.input--small .input__input-area {
  height: 32px;
  padding: 7px 12px;
  line-height: 16px;
}

.input--default {
  font-size: $font-size-xl;
  line-height: 150%;
}

.input--default input {
  padding: 8px 12px;
}

.input__input-area:hover {
  border-color: $color-border-neutral--light;
  border-width: 1px;
}

.input__input-area:focus {
  background-color: $color-background-neutral--white;
  border-color: $color-border-primary--main;
  border-width: 1px;
  outline: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset,
    0 0 6px 0 rgba(237, 84, 0, 0.6);
}

.input__input-area::placeholder {
  color: $color-content-neutral--lightest;
}

.input__input-area:disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  background-color: $color-background-neutral-sp--disable;
  border: 1px solid rgba(24, 42, 61, 0.12);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
}

.input__icon {
  position: absolute;
  top: 50%;
  display: flex;
  padding-left: 12px;
  pointer-events: none;
  transform: translateY(-50%);
}

.input__icon .icon,
.input__right-button .icon {
  color: $color-content-neutral-sp--light;
}

.input__icon--error .icon {
  color: $color-content-error--main;
}

.input__icon--error,
.input__right-button {
  right: 12px;
  left: auto;
}

.input__right-button {
  position: absolute;
  top: 50%;
  display: flex;
  padding-right: 0;
  background-color: inherit;
  border-style: none;
  transform: translateY(-50%);
}

.input__right-button:focus {
  outline: 0;
}

.input--disabled .input__right-button {
  pointer-events: none;
}

.input--with-left-icon .input__input-area {
  padding-left: 36px;
}

.input--with-right-icon .input__input-area {
  padding-right: 36px;
}

.input--error .input__input-area {
  padding-right: 36px;
  border-color: $color-border-error--main;
}

.input--error .input__input-area:hover {
  border-color: $color-border-error--dark;
}

.input--error .input__right-button {
  right: 36px;
}

.input--error.input--with-right-icon .input__input-area {
  padding-right: 60px;
}

.input__textarea {
  display: block;
  width: 100%;
  padding: 8px 40px 8px 12px;
  background-color: $color-user-secondary-not-entered;
  border-color: $color-border-input;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}

.input__textarea:hover {
  border-color: $color-border-neutral--black;
}

.input__textarea:focus {
  background-color: $color-background-neutral--white;
  border-color: $color-border-primary--main;
  border-width: 1px;
  outline: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset,
    0 0 6px 0 rgba(237, 84, 0, 0.6);
}

.input__textarea::placeholder {
  color: $color-content-neutral--lightest;
}

.input__textarea:disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
  background-color: $color-background-neutral--disabled;
  border-color: $color-border-neutral--disabled;
}

.input--error .input__textarea {
  border-color: $color-border-error--main;
}

.input--error .input__textarea:hover {
  border-color: $color-border-error--dark;
}

.input--multiLine.input--error .input__icon--error {
  top: 16px;
  right: 22px;
}

.input--multiLine.input--error:focus-within .input__icon--error {
  margin-top: 0;
}

.input--text-align-start {
  text-align: start;
}

.input--text-align-center {
  text-align: center;
}

.input--text-align-end {
  text-align: end;
}

.input--filled .input__textarea,
.input--filled .input__input-area {
  background-color: $color-background-neutral--white;
}

.input--value-disabled .input__input-area,
.input--value-disabled .input__textarea {
  background-color: $color-background-neutral--disabled;
  border-color: $color-border-neutral--disabled;
}
