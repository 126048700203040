@mixin \.detail-edit-fixed-area-01__information-area {
  display: grid;
  flex-direction: row;
  grid: 0 24px;
  grid-template-columns: 1fr 1fr 1fr;
}

.detail-edit-fixed-area-01__information-area {
  @include \.detail-edit-fixed-area-01__information-area;
}

@mixin \.information-wrap {
  width: 100%;
  padding: 16px;
}

.information-wrap {
  @include \.information-wrap;
}

@mixin \.login-form-inner {
  width: 440px;
}

.login-form-inner {
  @include \.login-form-inner;
}

@mixin \.login-form-header {
  font-size: $font-size-4xl;
  font-weight: $font-weight-bold;
  line-height: 150%;
}

.login-form-header {
  @include \.login-form-header;
}

@mixin \.login-content-area {
  width: 100%;
  min-height: 600px;
  margin-top: 80px;
  color: $color-content-neutral--black;
}

.login-content-area {
  @include \.login-content-area;
}

@mixin \.login-wrap {
  display: flex;
  height: 100vh;
  background-color: $color-background-neutral--lightest;
}

.login-wrap {
  @include \.login-wrap;
}

@mixin body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  overflow-y: hidden;
  font-family: "BIZ UDPGothic", "Helvetica Neue", Arial,
    "Hiragino Kaku Gothic ProN", Meiryo, "Hiragino Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  color: $color-content-neutral--black;
}

body {
  @include body;
}

@mixin html {
  height: 100%;
}

html {
  @include html;
}

@mixin \.lay-content {
  padding: 0 32px 32px;
}

.lay-content {
  @include \.lay-content;
}

@mixin \.lay-notification {
  padding: 16px;
}

.lay-notification {
  @include \.lay-notification;
}

@mixin \.lay-snackbar {
  position: sticky;
  top: 0;
  z-index: 10;
}

.lay-snackbar {
  @include \.lay-snackbar;
}

@mixin \.lay-snackbar__inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  padding: 16px;
}

.lay-snackbar__inner {
  @include \.lay-snackbar__inner;
}

@mixin \.lay-content-vertical-anchors-width {
  width: 160px;
}

.lay-content-vertical-anchors-width {
  @include \.lay-content-vertical-anchors-width;
}

@mixin \.lay-content-vertical-anchors-position {
  position: sticky;
  top: 321px;
}

.lay-content-vertical-anchors-position {
  @include \.lay-content-vertical-anchors-position;
}

@mixin \.admin-area {
  position: static;
  display: inherit;
  flex-flow: wrap;
  align-content: space-between;
  align-items: start;
  justify-content: start;
  justify-items: center;
  width: 100%;
  min-width: 375px;
  height: 100vh;
  overflow-x: auto;
  background: url("../assets/background.png"), lightgray 50% / cover no-repeat;
  background-color: #ece5df;
  background-blend-mode: soft-light;
}

.admin-area {
  @include \.admin-area;
}

@mixin \.admin-area_no-header {
  align-items: normal !important;
}

.admin-area_no-header {
  @include \.admin-area_no-header;
}

@mixin \.admin-area-with-button-footer {
  height: calc(100vh - 89px);
}

.admin-area-with-button-footer {
  @include \.admin-area-with-button-footer;
}

@mixin \.user-area {
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  background: url("../assets/background.png"), lightgray 50% / cover no-repeat;
  background-color: #ece5df;
  background-blend-mode: soft-light;
}

.user-area {
  @include \.user-area;
}

@mixin \.admin-header {
  min-width: 1440px;
}

.admin-header {
  @include \.admin-header;
}

@mixin \.admin-inner {
  justify-content: center;
  width: 100%;
  min-width: 375px;
  min-height: calc(100% - 68px);
  overflow-x: auto;
}

.admin-inner {
  @include \.admin-inner;
}

@mixin \.admin-user__wrapper {
  display: flex;
  min-width: 1440px;
  height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.admin-user__wrapper {
  @include \.admin-user__wrapper;
}

@mixin \.admin-user__info {
  width: 100%;
  min-width: 1200px;
  height: calc(100vh - 105px);
  padding: 24px;
  overflow-y: auto;
  background-color: $color-background-neutral--lightest;
}

.admin-user__info {
  @include \.admin-user__info;
}

@mixin \.admin-user__payment-info {
  min-width: 1440px;
  height: calc(100vh - 105px);
  padding: 24px 0 24px 24px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $color-background-neutral--lightest;
}

.admin-user__payment-info {
  @include \.admin-user__payment-info;
}

@mixin \.admin-user__info-detail {
  min-width: 720px;
  height: 100vh;
  background-color: $color-background-neutral--white;
  box-shadow: -3px 0 12px rgba(0, 0, 0, 0.14);
}

.admin-user__info-detail {
  @include \.admin-user__info-detail;
}

.admin-user__payment-info-detail .form-set:not(:last-child) {
  width: 427px;
}

.admin-user__payment-info-detail .form-set:last-child {
  width: 274px;
}

@media screen and (max-width: 1440px) {
  .admin-user__payment-info-detail > .layout-box {
    padding-right: 24px;
  }
}

@mixin \.admin-user__info-detail-list {
  height: calc(100vh - 565px) !important;
}

.admin-user__info-detail-list {
  @include \.admin-user__info-detail-list;
}

@mixin \.admin-user__info-detail--footer {
  background-color: $color-background-neutral--white;
  box-shadow: 0 -3px 12px rgba(0, 0, 0, 0.14);
}

.admin-user__info-detail--footer {
  @include \.admin-user__info-detail--footer;
}

@mixin \.admin-user__info-detail--footer-inner {
  padding: 11px 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.admin-user__info-detail--footer-inner {
  @include \.admin-user__info-detail--footer-inner;
}

@mixin \.admin-user__info-detail--footer-select {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.admin-user__info-detail--footer-select {
  @include \.admin-user__info-detail--footer-select;
}

@mixin \.admin-user__info-detail--footer-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 19px;
}

.admin-user__info-detail--footer-text {
  @include \.admin-user__info-detail--footer-text;
}

@mixin \.admin-user__info-detail--footer-button {
  padding: 8px 0;
}

.admin-user__info-detail--footer-button {
  @include \.admin-user__info-detail--footer-button;
}

@mixin \.admin-user-status__profile {
  width: 100%;
  padding: 16px 24px;
  background-color: $color-background-neutral--white;
  border-color: #6fa7ed;
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  border-radius: 8px;
}

.admin-user-status__profile {
  @include \.admin-user-status__profile;
}

@mixin \.admin-user-status__tabs {
  padding: 4px 8px !important;
  font-size: 14px !important;
}

.admin-user-status__tabs {
  @include \.admin-user-status__tabs;
}

@mixin \.admin-user__info-bar {
  width: 100%;
  padding: 16px;
  background-color: $color-background-neutral--lightest;
  border-radius: 8px;
}

.admin-user__info-bar {
  @include \.admin-user__info-bar;
}

.admin-user__payment-info-hospital.sheet {
  width: 480px;
}

@mixin \.admin-user__payment-info-detail {
  width: calc(100vw - 544px);
  min-width: 926px;
  margin-right: 24px;
}

.admin-user__payment-info-detail {
  @include \.admin-user__payment-info-detail;
}

@mixin \.admin-user__payment-info-detail-table {
  width: 100%;
  font-size: 12px;
}

.admin-user__payment-info-detail-table {
  @include \.admin-user__payment-info-detail-table;
}

@mixin \.admin-heading {
  width: 100%;
  padding: 4px 8px;
  background-color: $color-background-neutral--lightest;
  border-color: #6fa7ed;
  border-style: solid;
  border-width: 0 0 1px 3px;
}

.admin-heading {
  @include \.admin-heading;
}

.content-area {
  width: 1064px;
}

@mixin \.area-with-header-footer {
  min-height: calc(100vh - (108px + 68px));
  padding-bottom: 64px;
}

.area-with-header-footer {
  @include \.area-with-header-footer;
}

// mobile

.product-list-title {
  width: 100%;
}

@media only screen and (max-width: $mobile-view) {
  .product-list-title {
    padding: 0 16px;
  }

  .admin-inner {
    overflow-y: unset;
  }

  .admin-area {
    display: flex;
    height: calc(100vh - 74.5px);
    height: calc(100dvh - 74.5px);
  }

  .admin-area_no-header {
    align-items: normal !important;
    height: 100vh !important;
    height: 100dvh !important;
  }

  .area-with-header-footer {
    min-height: calc(100vh - (229px + 63.19px));
    padding-bottom: 64px;
  }

  .admin-area-with-button-footer {
    height: calc(100vh - 163.5px);
  }

  .user-body-sp-full-height {
    height: 100vh;
    height: 100dvh;
  }

  .user-footer--padding-84 {
    padding-bottom: 84px !important;
  }
}

@media only screen and (max-width: 828px) {
  .payment-method_centered-buttons {
    justify-content: center !important;
  }
}

a[href*="tel:"] {
  pointer-events: none;
}

@media screen and (max-width: $mobile-view) {
  a[href*="tel:"] {
    pointer-events: auto;
  }
}
