@import "./mixins";
@import "./variables";

.card-shop {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  padding: 24px;
  background-color: $color-background-neutral--white;
  border-radius: 16px;
}

.card-shop-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  padding: 16px;
  background: #f3f8f9;
  border: 1px solid $color-user-secondary--main;
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.card-shop--container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card-shop--inner {
  display: flex;
  gap: 16px;
}

.card-shop--content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.card-shop__title {
  width: 204px;
  height: 52px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  color: #3a3730;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-shop__price {
  font-size: $font-size-2xl;
  line-height: 150%;
  color: $color-user-primary--main;
}

.card-shop__price span {
  margin-left: 5px;
  font-size: $font-size-large;
}

.card-shop__button {
  display: flex;
  gap: 10px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 4px 10px;
  background-color: transparent;
  border: 1px solid #7dbcc9;
  border-radius: 100px;
}

.card-shop__button:focus {
  outline: 0;
}

.card-shop__button span {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: $font-size-xl;
  color: #0076a8;
  text-decoration-line: underline;
}

.card-shop__mobile .card-shop__title {
  width: 173px;
}

@media screen and (max-width: $mobile-view) {
  .card-shop {
    justify-content: center;
    width: 100% !important;
  }

  .card-shop__title {
    width: 171px;
  }
}
