@import "mixins";
@import "variables";

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 1064px;
  padding: 24px 108px;
  cursor: pointer;
}

.banner--gray {
  background-color: $color-user-background;
}

.banner--secondary-lightest {
  background-color: $color-user-secondary-lightest;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1064px;
  padding: 24px 8px;
  background-color: $color-background-neutral--white;
}

.banner-content--alt {
  font-size: $font-size-3xl;
  font-weight: $font-weight-bold;
  color: $color-user-neutral-black;
  opacity: 0.2;
}

// mobile

@media screen and (max-width: $mobile-view) {
  .banner {
    min-width: 100%;
    padding: 24px 16px;
  }

  .banner-content {
    width: 100%;
  }
}
