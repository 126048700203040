.receipt-page-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.receipt-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 595px;
  height: 100vh;
  padding: 14px;
  margin: 0 auto;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: $color-user-primary--main;
    border-bottom: 2px solid $color-border-primary--main;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__card {
    width: 100%;
    padding: 4px 8px;
    background-color: $color-user-primary--lightest;
    border-bottom: 2px solid $color-border-divider;

    &--large {
      padding: 8px;
    }
  }

  &__cell {
    padding: 4px 8px;
    line-height: 1;
    border-bottom: 1px solid $color-border-divider;

    &.receipt-page__cell--right {
      text-align: right;
    }
  }

  &__box {
    border: 1px solid $color-border-divider;
  }

  &__text {
    font-size: 9px;
    line-height: 150%;

    &--title {
      font-size: 10px;
      font-weight: 700;
    }

    &--amount {
      font-size: 15px;
      font-weight: 700;
    }

    &--small {
      font-size: 8px;
    }
  }

  &__table {
    font-size: 9px;
    line-height: 14px;

    thead {
      tr {
        font-weight: 700;
        background-color: $color-user-primary--lightest;
        border-bottom: 2px solid $color-border-divider;
      }
    }

    thead,
    tbody {
      th,
      td {
        height: 22px;
        padding: 4px 8px;

        &.text-right {
          text-align: right;
        }

        &.text-center {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: $color-background-neutral-sp--lightest;
        }
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background-color: $color-border-divider;
  }
}

@media print {
  .receipt-page-container {
    overflow: visible;
  }
}
