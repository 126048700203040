@import "variables";

.image--placeholder {
  display: flex;
  background-color: $color-background-neutral--light;
  border-radius: 4px;
}

.image {
  display: flex;
  object-fit: cover;
}

.image--avatar {
  width: 137px;
  height: 156px;
}

.image--picture {
  width: 278px;
  height: 156px;
}

.image--avatar_icon {
  display: flex;
  height: 156px;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  background-color: $color-background-neutral--light;
}

.image--avatar,
.image--picture {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-background-neutral--light;
}

.image--cart {
  border-radius: 8px;
}

.image--framed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background-color: $color-background-neutral--white;
}

.image--nouknow {
  width: 80px;
  height: 65px;
}

.image--brain_health {
  width: 80px;
  height: 53px;
}

.image--brain_suite {
  width: 80px;
  height: 69px;
}

.image--free_number {
  width: 35px;
  height: 18px;
}
.image--icon--disabled {
  opacity: 0.3;
  background-color: transparent;
}

@media screen and (max-width: $mobile-view) {
  .image--sp-video {
    width: 313px;
    height: 205px;
  }
}

.image--enjoy {
  position: absolute;
  top: 0;
  left: 50;
}
