@mixin \.font-base {
  font-family: "Helvetica Neue", Arial, "BIZ UDPGothic",
    "Hiragino Kaku Gothic ProN", Meiryo, "Hiragino Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}

.font-base {
  @include \.font-base;
}

@mixin \.font-component-alert-title {
  font-size: 16px;
  line-height: 24px;
}

.font-component-alert-title {
  @include \.font-component-alert-title;
}

@mixin \.font-component-badge-label {
  font-size: 12px;
}

.font-component-badge-label {
  @include \.font-component-badge-label;
}

@mixin \.font-component-button-large {
  font-size: 18px;
  font-weight: 700;
}

.font-component-button-large {
  @include \.font-component-button-large;
}

@mixin \.font-component-link-large {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.font-component-link-large {
  @include \.font-component-link-large;
}

@mixin \.font-component-button-medium {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.font-component-button-medium {
  @include \.font-component-button-medium;
}

@mixin \.font-component-link-medium {
  line-height: 160%;
}

.font-component-link-medium {
  @include \.font-component-link-medium;
}

@mixin \.font-component-button-small {
  font-size: 18px;
  font-weight: 700;
}

.font-component-button-small {
  @include \.font-component-button-small;
}

@mixin \.font-component-link-small {
  font-size: 12px;
}

.font-component-link-small {
  @include \.font-component-link-small;
}

@mixin \.font-component-link-2xs {
  font-size: 11px;
  line-height: 145%;
}

.font-component-link-2xs {
  @include \.font-component-link-2xs;
}

@mixin \.font-component-button-tiny {
  font-size: 11px;
  font-weight: 700;
}

.font-component-button-tiny {
  @include \.font-component-button-tiny;
}

@mixin \.font-component-chip {
  font-size: 13px;
  line-height: 130%;
}

.font-component-chip {
  @include \.font-component-chip;
}

@mixin \.font-component-helper-text {
  font-size: 12px;
}

.font-component-helper-text {
  @include \.font-component-helper-text;
}

@mixin \.font-component-input-label {
  font-size: 12px;
  line-height: 100%;
}

.font-component-input-label {
  @include \.font-component-input-label;
}

@mixin \.font-component-input-text {
  font-size: 16px;
}

.font-component-input-text {
  @include \.font-component-input-text;
}

@mixin \.font-component-list-subheader {
  font-size: 16px;
  line-height: 200%;
}

.font-component-list-subheader {
  @include \.font-component-list-subheader;
}

@mixin \.font-component-menu-item {
  font-size: 16px;
}

.font-component-menu-item {
  @include \.font-component-menu-item;
}

@mixin \.font-component-menu-item-dense {
  line-height: 130%;
}

.font-component-menu-item-dense {
  @include \.font-component-menu-item-dense;
}

@mixin \.font-component-table-header {
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
}

.font-component-table-header {
  @include \.font-component-table-header;
}

@mixin \.font-component-tooltip {
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
}

.font-component-tooltip {
  @include \.font-component-tooltip;
}

@mixin \.font-component-sp-link-medium {
  font-size: 18px;
  font-weight: 700;
}

.font-component-sp-link-medium {
  @include \.font-component-sp-link-medium;
}

@mixin \.font-component-sp-link-large {
  font-size: 20px;
  font-weight: 700;
}

.font-component-sp-link-large {
  @include \.font-component-sp-link-large;
}
