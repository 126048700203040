@import "variables";

.video--placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 306px;
  height: 172px;
  background-color: $color-background-neutral--hover;
}

.video-thumbnail {
  border-radius: 4px;
}
