@import "./mixins";
@import "./variables";

.order-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1064px;
  color: $color-user-neutral-black;
}

.order-details-title {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px 0;
}

.order-details-body {
  display: flex;
  width: 100%;
}

.order-details-image-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-details-image-group {
  display: flex;
  gap: 8px;
}

.order-details--image,
.order-details--image-empty {
  width: 128px;
  height: 128px;
  border-radius: 8px;
}

.order-details--image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.order-details--image-empty {
  background-color: #cccccc;
}

.order-details-info-container {
  width: 100%;
  padding: 16px;
}

@media only screen and (max-width: $mobile-view) {
  .order-details-body {
    flex-direction: column;
  }

  .order-details-preview {
    display: none;
  }
}
