@import "./mixins";
@import "./variables";

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 68px;
  padding: 8px 16px;
}

.header--bg-white {
  background: $color-content-neutral--white;
}

.header--bg-none {
  background-color: transparent;
}

.header--bg-rough-yellow {
  width: 100%;
  background: url("../assets/background.png"), lightgray 50% / cover no-repeat;
  background-color: #ebd3a0;
  background-size: cover;
  background-blend-mode: soft-light;
}

.header--no-right-control {
  padding: 16px;
}

.header__left {
  display: flex;
  gap: 24px;
  align-items: center;
  line-height: 120%;
}

.header__right {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header__left-logo {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: $font-size-2xl;
  font-weight: $font-weight-bold;
  line-height: 150%;
  color: $color-user-primary--main !important;
  text-decoration: none !important;
}

.header__right-icon {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 50%;
}

.header__btn {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-color: transparent;
  border-radius: 100px;
}

.header__btn:hover {
  box-shadow: none;
}

.header__btn:active {
  box-shadow: none;
}

.header__btn:disabled {
  pointer-events: none;
  box-shadow: none;
}

.header__btn:focus {
  outline: 0;
}

.header__btn-message {
  color: $color-content-neutral--white;
  background-color: #0076a8;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.header__btn-user {
  color: $color-user-primary--main;
  background-color: $color-content-neutral--white;
  box-shadow: 0 2px 0 0 rgba(132, 33, 0, 0.5);
}

.header__btn-user-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.header__btn-user__icon {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 4px;
  background: $color-user-primary--main;
  border-radius: 100px;
}

.header__btn-user-text-wrapper {
  display: flex;
  gap: 4px;
  align-items: flex-start;
}

.header__btn-user-courtesy {
  font-size: $font-size-large;
}

.header__btn-message__icon {
  position: relative;
}

.header__btn-message__icon .badge {
  position: absolute;
  top: 0.5px;
  right: -8px;
  background-color: $color-user-primary--main;
}

.header__btn-message-container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 4px 24px;
}

// mobile

@media only screen and (max-width: $mobile-view) {
  .header {
    z-index: 999;
    padding: 4px 4px 4px 16px;
  }

  .header--white {
    box-shadow: $shadow-level-01;
  }

  .header__left-logo {
    font-size: $font-size-xl;
    line-height: 120%;
  }

  .header__right {
    gap: 4px;
  }

  .header__btn {
    font-size: $font-size-large;
  }

  .header__btn-user,
  .header__btn-message {
    border-radius: 4px !important;
  }

  .header__btn-user-container,
  .header__btn-message-container {
    flex-direction: column;
    gap: 0;
  }

  .header__btn-user-container {
    padding: 0 4px;
  }

  .header__btn-user__icon .badge {
    color: $color-user-primary--main;
  }

  .header__btn-user .icon {
    display: none !important;
  }

  .header__btn-message-container {
    width: 64px;
    height: 56px;
    padding: 0;
  }

  .header__btn-message__icon {
    width: 28px;
    height: 28px;
  }
}
