@import "./mixins";
@import "./variables";

.radio-group {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  font-size: $font-size-xl;
}

.radio-group--multi-column {
  flex-direction: column;
}

.radio-group--vertical {
  flex-direction: column;
  gap: 4px;
}

.radio-group--wrap {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.radio-group--wrap-content-start {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: start;
  width: 100%;
}

.radio-group__column--2 {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
  gap: 8px 24px;
}

.radio-group__column--3 {
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group__column--4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group__column--5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group__column--6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group-row {
  display: inline-flex;
  gap: 16px;
}

.radio-group-row__column--1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px 24px;
}

.radio-group-row__column--2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px 24px;
}

.radio-group-row__column--3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group-row__column--4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group-row__column--5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio-group-row__column--6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 24px;
}

.radio {
  display: inline-flex;
  gap: 8px;
  padding: 6px 0;
  margin-bottom: 0;
  cursor: pointer;
}

.radio--disabled {
  color: $color-content-neutral--disabled;
  pointer-events: none;
}

.radio__input {
  position: relative;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 2px;
  appearance: none;
  cursor: pointer;
  background-color: $color-background-neutral--white;
  border: 2px solid $color-content-neutral--lightest;
  border-radius: 50%;
}

.radio__input-container {
  display: flex;
  align-items: center;
  justify-content: start;
}

.radio__input:hover {
  border-color: $color-user-primary--main;
}

.radio__input:checked {
  border-color: $color-user-primary--main;
}

.radio__input:checked::after {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  display: block;
  width: 8px;
  height: 8px;
  content: "";
  background-color: $color-user-primary--main;
  border-radius: 50%;
}

.radio__input:disabled {
  border-color: $color-border-divider;
}

.radio__input:disabled:checked {
  border-color: $color-content-neutral--lightest;
}

.radio__input:disabled:checked::after {
  background-color: $color-content-neutral--lightest;
}

.radio--border {
  padding: 12px 8px;
  border: 1px solid $color-border-outline;
  border-radius: 4px;
  box-shadow: 0 3px 0 0 rgba(24, 42, 61, 0.2);
}

.radio--border-checked {
  margin: -1px;
  background-color: $color-background-primary--lightest;
  border: 2px solid $color-border-primary--main;
  border-radius: 4px;
  box-shadow: none;
}

.radio--bg-disabled {
  background-color: $color-background-neutral--disabled;
}

.radio-group--selected-none .radio {
  background-color: $color-user-secondary-not-entered;
}

@media screen and (max-width: $mobile-view) {
  .radio-group--to-column.radio-group__column--2 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .radio-group--to-column.radio-group__column--3 {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: none;
  }

  .radio-group--to-column.radio-group__column--4 {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: none;
  }

  .radio-group--to-column.radio-group__column--5 {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: none;
  }

  .radio-group--to-column.radio-group__column--6 {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: none;
  }
}
