@import "./mixins";
@import "./variables";

.logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo--horizontal {
  background-image: $image-logo-horizontal;
}

.logo--small {
  width: 117px;
  height: 32px;
  margin-top: 7px;
}

.logo--medium {
  width: 160px;
  height: 45px;
}

.logo--large {
  width: 300px;
  height: 84px;
}
