@import "./mixins";
@import "./variables";

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 10px 8px;
  overflow: hidden;
  color: $color-content-neutral--black;
  white-space: nowrap;
  background-color: $color-background-neutral--white;
  border-color: $color-border-input;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
}

.dropdown-button .icon {
  color: $color-content-neutral--light;
}

.dropdown-button:hover {
  border-color: $color-border-neutral--light;
}

.dropdown-button:focus {
  padding: 9px 7px;
  border-color: $color-border-primary--main;
  border-width: 2px;
}

.dropdown-button:disabled {
  color: $color-content-neutral--light;
  background: $color-background-neutral--disabled;
}

.dropdown-button:disabled:hover {
  border-color: $color-border-input;
}

.dropdown-button.dropdown-button--small {
  height: 32px;
  padding: 8px 6px;
  font-size: $font-size-small;
}

.dropdown-button.dropdown-button.dropdown-button--small:focus {
  padding: 7px 5px;
  border-width: 2px;
}

.dropdown-button.dropdown-button--error {
  border-color: $color-border-error--main;
}

.dropdown-button.dropdown-button--error:hover {
  border-color: $color-border-error--dark;
}

.dropdown-button.dropdown-button--error:focus {
  border-color: $color-border-error--main;
}

.dropdown-button__error-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.dropdown-button__error-icon .icon {
  color: $color-content-error--main;
}

.dropdown-button__inner {
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.dropdown-button__inner.dropdown-button__inner--placeholder {
  color: $color-content-neutral--disabled;
}

.dropdown__item-list {
  display: flex;
  flex-direction: column;
  max-height: 164px;
  padding: 0;
  overflow-y: overlay;
  background-color: $color-background-neutral--white;
  border-radius: 4px;
  box-shadow: $shadow-level-03;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  background-clip: content-box;
  border: 3px solid transparent;
}

::-webkit-scrollbar-track {
  background: none;
}

.dropdown-item {
  flex-shrink: 0;
  padding: 4px 16px;
  overflow: hidden;
  font-size: $font-size-xs;
  text-overflow: ellipsis;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: $color-background-primary--hover-faded;
}

.dropdown-item--selected {
  font-weight: $font-weight-bold;
  color: $color-content-primary--main;
  background-color: $color-background-primary--active;
}

.dropdown-item--focused {
  background-color: $color-background-primary--hover-faded;
}
