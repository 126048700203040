$color-background-primary--main: #cf4900;
$color-background-primary--light: #c8e0fd;
$color-background-primary--lightest: #f6e8be;
$color-background-primary--lightest-opacity-7: rgba(9, 106, 226, 0.07);
$color-background-primary--dark: #0054b8;
$color-background-primary--hover: #0456bb;
$color-background-primary--hover-faded: #deedff;
$color-background-primary--hovered-selected-line: #bcd6f8;
$color-background-primary--active: #cde3ff;
$color-background-primary--selected: #014799;
$color-background-primary--accent: #096ae2;
$color-background-secondary--main: #f6e8be;
$color-background-secondary--light: #96dccd;
$color-background-secondary--lightest: #f2fffc;
$color-background-secondary--dark: #0d876e;
$color-background-secondary--hover: #7dd5c3;
$color-background-secondary--hover-faded: #e0fff9;
$color-background-secondary--active: #258e78;
$color-background-secondary--selected: #085343;
$color-background-neutral--black: #3a3730;
$color-background-neutral--light: #eff4fe;
$color-background-neutral--lightest: #f0f3f7;
$color-background-neutral--dark: #424242;
$color-background-neutral--hover: #757575;
$color-background-neutral--hover-faded: #eaeaea;
$color-background-neutral--hovered-white-line: #f5f5f5;
$color-background-neutral--hovered-gray-line: #eaeaea;
$color-background-neutral--white: #ffffff;
$color-background-neutral--disabled: #eeeeee;
$color-background-neutral--active: #e0e0e0;
$color-background-neutral--selected: #636363;
$color-background-neutral--extra-bk-80: rgba(0, 0, 0, 0.8);
$color-background-neutral--extra-bk-50: rgba(0, 0, 0, 0.5);
$color-background-neutral--extra-bk-25: rgba(0, 0, 0, 0.25);
$color-background-neutral--extra-bk-16: rgba(0, 0, 0, 0.16);
$color-background-neutral--extra-bk-10: rgba(0, 0, 0, 0.1);
$color-background-neutral--extra-bk-6: rgba(0, 0, 0, 0.06);
$color-background-neutral--extra-bk-4: rgba(0, 0, 0, 0.04);
$color-background-neutral--extra-wh-80: rgba(255, 255, 255, 0.8);
$color-background-neutral--extra-wh-50: rgba(255, 255, 255, 0.5);
$color-background-neutral--extra-wh-25: rgba(255, 255, 255, 0.25);
$color-background-neutral--extra-wh6: rgba(255, 255, 255, 0.06);
$color-background-neutral--extra-wh3: rgba(255, 255, 255, 0.03);
$color-background-neutral-sp--black: #3a3730;
$color-background-neutral-sp--light: #acaaa4;
$color-background-neutral-sp--lightest: #f5f3f0;
$color-background-neutral-sp--disable: #e3e1de;
$color-background-error--main: #a21b11;
$color-background-error--light: #f6aba5;
$color-background-error--lightest: #fff3f3;
$color-background-error--dark: #d33a2f;
$color-background-error--hover: #ef5b50;
$color-background-error--hover-faded: #feeceb;
$color-background-error--hovered-error-line: #feeceb;
$color-background-error--active: #fbdad9;
$color-background-error--selected: #e54135;
$color-background-success--main: #0a810f;
$color-background-success--light: #81c784;
$color-background-success--lightest: #f6fbf6;
$color-background-success--dark: #388e3c;
$color-background-success--hover: #4faa53;
$color-background-success--hover-faded: #e8f5e9;
$color-background-success--active: #c8e6c9;
$color-background-success--selected: #43a047;
$color-background-information--main: #075797;
$color-background-information--light: #64b5f6;
$color-background-information--lightest: #f4fafe;
$color-background-information--dark: #1980d2;
$color-background-information--hover: #339bef;
$color-background-information--hover-faded: #e3f1fd;
$color-background-information--active: #bbdffb;
$color-background-information--selected: #1e8de5;
$color-background-warning--main: #d6a000;
$color-background-warning--light: #ffdc7d;
$color-background-warning--lightest: #fff8e4;
$color-background-warning--dark: #764e09;
$color-background-warning--hover: #c0940f;
$color-background-warning--hover-faded: #fff2c9;
$color-background-warning--active: #ffecb3;
$color-background-warning--selected: #a67f08;
$color-background-sheet-corner: #0076a8;
$color-background-sheet-corner-end: #d3dbe5;
$color-background-graph--pattern01-not-supported: #ffffff;
$color-background-graph--pattern02-not-supported: #ffffff;
$color-background-graph--pattern03-not-supported: #ffffff;
$color-background-graph--pattern04-not-supported: #ffffff;
$color-content-primary--main: #cf4900;
$color-content-primary--light: #f6e8be;
$color-content-primary--dark: #032957;
$color-content-primary--accent: #096ae2;
$color-content-primary--accent-faded: #0965d7;
$color-content-secondary--main: #7bdbc7;
$color-content-secondary--light: #96dccd;
$color-content-secondary--dark: #085343;
$color-content-secondary--accent: #5eeacd;
$color-content-secondary--accent-faded: #c7fdef;
$color-content-neutral--black: #253345;
$color-content-neutral--light: #7a8593;
$color-content-neutral--lightest: #b4bbc6;
$color-content-neutral--dark: #212121;
$color-content-neutral--white: #ffffff;
$color-content-neutral--disabled: #97a5b5;
$color-content-neutral-sp--black: #3a3730;
$color-content-neutral-sp--light: #736f66;
$color-content-neutral-sp--lightest: #acaaa4;
$color-content-neutral-sp--disable: #acaaa4;
$color-content-error--main: #a21b11;
$color-content-error--light: #e78d87;
$color-content-error--dark: #d33a2f;
$color-content-error--accent: #ff2717;
$color-content-error--accent-faded: #ff8980;
$color-content-success--main: #18611b;
$color-content-success--light: #81c784;
$color-content-success--dark: #388e3c;
$color-content-success--accent: #00e609;
$color-content-success--accent-faded: #b9f6bc;
$color-content-information--main: #075797;
$color-content-information--light: #64b5f6;
$color-content-information--dark: #1980d2;
$color-content-information--accent: #29a0ff;
$color-content-information--accent-faded: #82c7ff;
$color-content-warning--main: #d6a000;
$color-content-warning--light: #ffdc7d;
$color-content-warning--dark: #935d01;
$color-content-warning--accent: #ffc400;
$color-content-warning--accent-faded: #ffe57f;
$color-border-divider: rgba(0, 0, 0, 0.12);
$color-border-outline: rgba(0, 0, 0, 0.23);
$color-border-input: rgba(0, 0, 0, 0.18);
$color-border-divider-white: rgba(255, 255, 255, 0.12);
$color-border-neutral--black: #000000;
$color-border-neutral--dark: rgba(0, 0, 0, 0.68);
$color-border-neutral--light: rgba(0, 0, 0, 0.54);
$color-border-neutral--white: #ffffff;
$color-border-neutral--disabled: rgba(0, 0, 0, 0.12);
$color-border-primary--main: #cf4900;
$color-border-primary--light: #6fa7ed;
$color-border-primary--dark: #032249;
$color-border-secondary--main: #7bdbc7;
$color-border-secondary--light: #96dccd;
$color-border-secondary--dark: #0d876e;
$color-border-error--main: #a21b11;
$color-border-error--light: #f0bcb8;
$color-border-error--dark: #d33a2f;
$color-border-success--main: #18611b;
$color-border-success--light: #81c784;
$color-border-success--dark: #388e3c;
$color-border-information--main: #075797;
$color-border-information--light: #64b5f6;
$color-border-information--dark: #1976d2;
$color-border-warning--main: #d6a000;
$color-border-warning--light: #ffdc7d;
$color-border-warning--dark: #935d01;
$color-user-primary--main: #cf4900;
$color-user-primary--light: #e79376;
$color-user-primary--lightest: #f6e8be;
$color-user-secondary--main: #0076a8;
$color-user-secondary--light: #91c5cf;
$color-user-secondary-lightest: #e4eff1;
$color-user-secondary-not-entered: #e2f4f8;
$color-user-neutral-light: #736f66;
$color-user-neutral-black: #3a3730;
$color-user-primary-button-base: #d66a2f;
$color-user-secondary-button-base: #0b87bc;
$color-user-background: #ece5df;
$color-user-neutral--lightest: #acaaa4;
$color-text-normal: #111111;
$font-size-5xl: 46px;
$font-size-4xl: 40px;
$font-size-3xl: 32px;
$font-size-2xl: 24px;
$font-size-1xl: 20px;
$font-size-xl: 18px;
$font-size-large: 16px;
$font-size-base: 14px;
$font-size-small: 13px;
$font-size-xs: 12px;
$font-size-2xs: 11px;
$font-size-3xs: 10px;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;
$shadow-level-01: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
$shadow-level-02: 0 3px 12px 0 rgba(0, 0, 0, 0.14);
$shadow-level-02top: 0 -3px 12px 0 rgba(0, 0, 0, 0.14);
$shadow-level-02right: 3px 0 12px 0 rgba(0, 0, 0, 0.14);
$shadow-level-02left: -3px 0 12px 0 rgba(0, 0, 0, 0.14);
$shadow-level-02_fab: 0 3px 12px 0 rgba(0, 0, 0, 0.3);
$shadow-level-03: 0 8px 24px 0 rgba(0, 0, 0, 0.14);
$shadow-level-04: 0 16px 32px 0 rgba(0, 0, 0, 0.16);
$image-logo-horizontal: url("../assets/logo_yoko.png");
$image-sample-image: url("../assets/sampleImage.svg");
$image-sample-avatar: url("../assets/sampleAvatar.png");
$image-chart-pattern-1: url("../assets/chart-pattern-1.png");
$image-chart-pattern-2: url("../assets/chart-pattern-2.png");
$image-chart-pattern-3: url("../assets/chart-pattern-3.png");
$image-chart-pattern-4: url("../assets/chart-pattern-4.png");
$image-chart-mark-1: url("../assets/chart-mark-1.svg");
$image-chart-mark-2: url("../assets/chart-mark-2.svg");
$image-chart-mark-3: url("../assets/chart-mark-3.svg");
$image-service-icon-1: url("../assets/service-icon-1.svg");
$image-service-icon-2: url("../assets/service-icon-2.svg");
$image-service-icon-3: url("../assets/service-icon-3.svg");
$image-service-icon-4: url("../assets/service-icon-4.svg");
$image-service-icon-5: url("../assets/service-icon-5.svg");
$image-service-icon-6: url("../assets/service-icon-6.svg");
$image-service-icon-7: url("../assets/service-icon-7.svg");
$image-service-icon-8: url("../assets/service-icon-8.svg");
$image-service-icon-9: url("../assets/service-icon-9.svg");
$image-service-icon-10: url("../assets/service-icon-10.svg");
$image-service-icon-11: url("../assets/service-icon-11.svg");
$image-service-icon-12: url("../assets/service-icon-12.svg");
$image-service-icon-13: url("../assets/service-icon-13.svg");
$image-service-icon-14: url("../assets/service-icon-14.svg");
$image-service-icon-15: url("../assets/service-icon-15.svg");
$image-service-icon-16: url("../assets/service-icon-16.svg");
$image-service-icon-17: url("../assets/service-icon-17.svg");
$image-service-icon-18: url("../assets/service-icon-18.svg");
$mobile-view: 768px;
$content-width: 1064px;
$content-min-width: 1076px;
$mobile-min-width: 320px;
