@import "./mixins";
@import "./variables";

.sheet {
  display: flex;
  justify-content: center;
  width: 100%;
}

.sheet--overflow {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}

.sheet--white {
  background-color: $color-background-neutral--white;
}

.sheet--lightest {
  background-color: $color-user-primary--lightest;
}

.sheet--neutral-lightest {
  background: $color-background-neutral--lightest;
}

.sheet--rough-blue-arch {
  width: 100%;
  clip-path: url("#ArchBackground");
  background: url("../assets/background.png") no-repeat;
  background-color: #dceff2;
  background-size: cover;
  background-blend-mode: color-burn;
}

.sheet--rough-yellow {
  width: 100%;
  background: url("../assets/background.png"), lightgray 50% / cover no-repeat;
  background-color: #ebd3a0;
  background-size: cover;
  background-blend-mode: soft-light;
}

.sheet--rough-yellow-wavy::after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 30px;
  clip-path: url("#WavyBackground");
  content: "";
  background: url("../assets/background.png"), lightgray 50% / cover no-repeat;
  background-color: #ebd3a0;
  background-size: cover;
  background-blend-mode: soft-light;
}

.sheet--primary-lightest {
  background-color: $color-background-primary--lightest;
}

.sheet--secondary-lightest {
  background-color: $color-user-secondary-lightest;
}

.sheet--header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 35px;
  padding: 4px 8px;
  background-color: $color-background-neutral--lightest;
  border-color: $color-border-primary--light;
  border-style: solid;
  border-width: 0 0 1px 3px;
  border-radius: 0 !important;
}

.sheet--left-border {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px !important;
  border-left: 4px solid $color-user-secondary--main;
}

.sheet--card {
  background: $color-background-neutral--white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.25);
}

.sheet--footer {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding: 16px 32px;
  border-top: 1px solid $color-border-divider;
}

.sheet--border {
  border-color: $color-border-divider;
  border-style: solid;
  border-width: 1px;
}

.sheet--gray {
  background-color: $color-background-neutral--lightest;
}

.sheet--gray-sp {
  background-color: $color-background-neutral-sp--lightest;
}

.sheet--yellow {
  background-color: $color-background-secondary--main;
}

.sheet--blue {
  background-color: $color-user-secondary-lightest;
}

.sheet--border-blue {
  border-color: $color-user-secondary--light;
  border-style: solid;
  border-width: 1px;
}

.sheet--border-red {
  border-color: $color-user-primary--light;
  border-style: solid;
  border-width: 1px;
}

.sheet--border-bottom--primary {
  border-bottom: 5px solid $color-user-primary--main;
}

.sheet--border-bottom--secondary {
  border-bottom: 5px solid $color-user-secondary--main;
}

.sheet--border-radius {
  border-radius: 8px;
}

.sheet--corner-new {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 52px;
  height: 32px;
  font-size: 16px;
  font-weight: 700;
  color: $color-content-neutral--white;
  background-color: $color-background-sheet-corner;
  border-radius: 8px 0;
}

.sheet--corner-background-new {
  position: absolute;
  top: 0;
  left: 0;
  width: 54px;
  height: 34px;
  background-color: $color-background-neutral--white;
  border-radius: 8px 0;
}

.sheet--rough-orange {
  width: 100%;
  background-color: #d66a2f;
  background-image: url("../assets/button-background.png");
  background-size: cover;
  background-blend-mode: color-burn;
}

.sheet--colored-border {
  background-size: auto;
  background-blend-mode: hard-light;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-image: linear-gradient(
    to right,
    #009139 16.67%,
    #221a17 16.67% 33.34%,
    #f08200 33.34% 50.01%,
    #009139 50.01% 66.68%,
    #221a17 66.68% 83.35%,
    #f08200 83.35%
  );
  border-image-slice: 1;
}

.sheet--corner-end {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 84px;
  height: 32px;
  font-size: 16px;
  font-weight: 700;
  color: $color-content-neutral--light;
  background-color: $color-background-sheet-corner-end;
  border-radius: 8px 0;
}

.sheet--corner-background-end {
  position: absolute;
  top: 0;
  left: 0;
  width: 86px;
  height: 34px;
  background-color: $color-background-neutral--white;
  border-radius: 8px 0;
}

.sheet--relative {
  position: relative;
}

.sheet--pointer {
  cursor: pointer;
}

@media screen and (max-width: $mobile-view) {
  .sheet {
    min-width: 100% !important;
  }

  .sheet--sp-padding {
    padding-right: 16px !important;
    padding-left: 16px !important;
  }

  .sheet--rough-blue-arch {
    clip-path: url("#ArchBackgroundSp");
  }

  .sheet--rough-yellow-wavy::after {
    clip-path: url("#WavyBackgroundSp");
  }

  .sheet--neutral-lightest {
    background: $color-background-neutral-sp--lightest;
  }
}
